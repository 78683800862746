import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/shared/services/api.service';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-kontakti',
  templateUrl: './kontakti.component.html',
  styleUrls: ['./kontakti.component.scss']
})
export class KontaktiComponent implements OnInit {

  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  row:any;
  loadingVisible = true;
  kontakti: any;
  obrisiPopupVisible: boolean=false;
  idZaObrisat: any;

  constructor(
    private router: Router,
    private api: ApiService
    ) {
      this.token = sessionStorage.getItem('token');
      this.nivoPrava=sessionStorage.getItem('nivoPrava');
      this.idkorisnik=sessionStorage.getItem('idkorisnik');
      this.kor_ime=sessionStorage.getItem('kor_ime');
      this.kor_prezime=sessionStorage.getItem('kor_prezime');
      this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
    }

  ngOnInit(): void {
    this.dohvatiKontakte();
  }

  onToolbarPreparing(e: any) { 
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'naslovTablice'   
      },
      {
        location: 'after',
        template: "dodaj",
      },
    )
  }

  onFocusedRowChanged(e) {
    this.row=e.row;
  }

  onInitNewRow(e){
    this.router.navigate(['kontakti/dodaj-kontakt/']);  
  }

  dohvatiKontakte(){
    let podaci = {
      access_token: this.token,
    }
    this.api.dohvatiKontakte(podaci).subscribe(res => {
      this.loadingVisible = false;
      this.kontakti = res.data;   
    })
  }

  uredi(e){
    this.router.navigate(['kontakti/uredi-kontakt/' + e.data.idkontakt]);  
  }
  
  brisanje(e){
    this.idZaObrisat=e.data.idkontakt;
    this.obrisiPopupVisible=true;
  }

  obrisi(){
    let podaci = {
      access_token: this.token,
      idkorisnik: this.idkorisnik,
      idkontakt:this.idZaObrisat
    }
    this.api.obrisiKontakt(podaci).subscribe(res => {
        if(res.success){
          notify(res.message,'success');
          this.obrisiPopupVisible=false;
        }else{
          notify(res.message,'error');
          this.obrisiPopupVisible=false;
        }
        this.dohvatiKontakte();
    })
  }

  odustani(){
    this.obrisiPopupVisible=false;
  }

}