<h2 style="text-align: left ;margin-left: 40px;">Događaj</h2> 
<div class="content-block dx-card">
    <div class="responsive-paddings" style="margin-left:75px;">
        <dx-load-panel
            #loadPanel
            shadingColor="rgba(0,0,0,0.4)"
            [(visible)]="loadingVisible"
            [showIndicator]="true"
            [showPane]="true"
            [shading]="true"
            [closeOnOutsideClick]="false">
        </dx-load-panel>
        <dx-form
            labelLocation="top"
            [(formData)]="dogadaj"
            [showColonAfterLabel]="true"
            validationGroup="forma"
            (onContentReady)="onContentReady($event)"
            >
            
                <dxi-item dataField="do_datum" [label]="{text: 'Datum'}" editorType="dxDateBox" 
                    [editorOptions]="{ stylingMode:'outlined', width:'200px', readOnly: samoPregled}">
                        <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="do_naslov" [label]="{text: 'Naslov'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined', width:'400px', readOnly: samoPregled }">
                        <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="do_opis" [label]="{text: 'Tekst događaja'}" editorType="dxTextArea" 
                    [editorOptions]="{ stylingMode:'outlined',height:'180px', width:'600px', readOnly: samoPregled }">
                        <dxi-validation-rule  type="required" message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="do_web" [label]="{text: 'Web'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined', width:'500px', readOnly: samoPregled }">
                    <dxi-validation-rule type="custom" [validationCallback]="validationCallback" message="Mora sadržavati https://"></dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="do_aktivan" [label]="{text: 'Aktivan'}" editorType="dxCheckBox" cssClass="ikona" 
                    [editorOptions]="{ iconSize:'50px' , value:aktivna, readOnly: samoPregled}">
                </dxi-item>

                <dxi-item>
                    <label class="image-upload-container btn btn-bwm">
                        <span class="boxSpan"><i class="dx-icon-image dx-icon-custom-style"> </i> </span>
                        <input #imageInput
                               type="file"
                               accept="image/*"
                               multiple="multiple"
                               (change)="processFile(imageInput)">
                      </label>
                    
                      <div *ngIf="triSlike[0]" class="img-preview-container">
                      
                        <div class="img-preview"
                            *ngFor="let slika of triSlike; let i = index"
                             [ngStyle]="{'background-image': 'url('+ slika + ')'}"
                             (click)="openImage()">
                             <i class="dx-icon-remove" style="float:right;cursor: pointer;font-size: 24px;" (click)="remove(slika,i)"></i>
                        </div>
                      </div>
 
                </dxi-item>

                <dxi-item>
                    <dx-button 
                        validationGroup="forma"
                        type="success"
                        (onClick)="spremi(dogadaj)"
                        >SPREMI
                    </dx-button>

                    <dx-button 
                        validationGroup="forma"
                        type="danger"
                        style="margin-left: 10px ;"
                        (onClick)="odustani()"
                        >ODUSTANI
                    </dx-button>
                </dxi-item>
        
        </dx-form>
    </div>
</div>
<!-- Popup za galeriju -->
<dx-popup
[closeOnOutsideClick]="false"
[showCloseButton]="true"
[(visible)]="popupImageVisible"
[height]="900"
[width]="1000"
title="Odabrane fotografije"
>
    <dx-gallery
      #gallery
      id="gallery"
      [dataSource]="slikeZaPrikaz"
      [loop]="true"
      [showIndicator]="true"
      [height]="800"
      [swipeEnabled]="true"
    ></dx-gallery>
</dx-popup>