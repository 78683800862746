import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

import notify from 'devextreme/ui/notify';
import validationEngine from 'devextreme/ui/validation_engine';

import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';


@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss']
})

export class KontaktComponent implements OnInit {

  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  mapMarkers = [];
  keys={};
  formattedAddress: any;
  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  kontaktID:any;
  kontakt:any={};
  validacijaForma: any;
  validno: boolean=false;
  loadingVisible:boolean=false;
  validno2: boolean;
  isValid: boolean=true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private api: ApiService,
    private route: ActivatedRoute
  ) {
    this.validationCallback = this.validationCallback.bind(this);
    if(isNaN(this.route.snapshot.params['id']) || this.route.snapshot.params['id'] === null || this.route.snapshot.params['id'] === undefined ){
      this.kontaktID = null;
    }else{
      this.kontaktID = +this.route.snapshot.params['id'];
    }
    this.token = sessionStorage.getItem('token');
    this.nivoPrava=sessionStorage.getItem('nivoPrava');
    this.idkorisnik=sessionStorage.getItem('idkorisnik');
    this.kor_ime=sessionStorage.getItem('kor_ime');
    this.kor_prezime=sessionStorage.getItem('kor_prezime');
    this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
    this.keys["google"]="AIzaSyAInEYFG0_ykzrvxDU8Qmf7c39MRO_qXCk";
   }

  ngOnInit(): void {
    if(this.kontaktID!=undefined && this.kontaktID !=null ){
      this.loadingVisible=true;
      this.dohvatiJedanKontakt();
    }
  }
  onContentReady(e){
    this.validacijaForma = validationEngine.validateGroup("forma");
  }

  validationCallback(e: any) {
    if(e.value!=undefined && e.value!=null && e.value!=""){
      if(e.value.includes("https://")){
        this.isValid=true;
      }else{
        this.isValid=false;
      }
    }else{
      this.isValid=true;
    }
    return this.isValid;
  }

  dohvatiJedanKontakt(){
    let podaci={
      access_token: this.token,
      idkontakt:this.kontaktID
    }
    this.api.dohvatiJedanKontakt(podaci).subscribe(res => {
       this.kontakt=res.data[0];
       this.formattedAddress=this.kontakt.ko_adresa;
       let lokacija = {lat:this.kontakt.ko_lat,lng: this.kontakt.ko_lon}
       this.mapMarkers=[]
       this.mapMarkers.push({ location: lokacija });
       setTimeout(() => { 
        this.loadingVisible=false;
        }, 500)
     })
  }

  spremi(e){
    //PROVJERAVANJE VALIDNOSTI SVAKOG PRAVILA
    let br=0;
    for(let i=0;i<this.validacijaForma.brokenRules.length;i++){
      if(this.validacijaForma.brokenRules[i].isValid==true){
        br++;
      }
    }if(br==this.validacijaForma.brokenRules.length){
      this.validno=true;
    }else{
      this.validno=false;
    }
    if(this.mapMarkers[0]==undefined || null){
      this.validno2=false;
    }else{
      this.validno2=true;
    }
    if(this.validno===true && this.validno2===true && this.isValid===true){
      if(this.kontaktID == undefined || this.kontaktID == null){ //DODAVANJE NOVOG KONTAKTA
        if(this.mapMarkers[0]==undefined){
          this.mapMarkers.push({ location: null + "," +null })
        }
        let podaci = {
          access_token: this.token,
          naziv:e.ko_naziv,
          adresa:this.formattedAddress,
          telefon:e.ko_telefon,
          mobitel:e.ko_mobitel,
          mail:e.ko_email,
          web:e.ko_web,
          lat:this.mapMarkers[0].location.lat,
          lon:this.mapMarkers[0].location.lng,
        }
        this.api.dodajKontakt(podaci).subscribe(res => {
            if(res.success === true){
              notify(res.message, 'success');
              this.router.navigate(['kontakti/']); 
            } else {
              notify(res.message, 'error');
            }
        })
      }else{                   //UREĐIVANJE KONTAKTA
        let podaci = {
          access_token: this.token,
          idkontakt:this.kontaktID,
          naziv:e.ko_naziv,
          adresa:this.formattedAddress,
          telefon:e.ko_telefon,
          mobitel:e.ko_mobitel,
          mail:e.ko_email,
          web:e.ko_web,
          lat:this.mapMarkers[0].location.lat,
          lon:this.mapMarkers[0].location.lng
        }
        this.api.urediKontakt(podaci).subscribe(res => {
            if(res.success === true){
              notify(res.message, 'success');
              this.router.navigate(['kontakti/']); 
            } else {
              notify(res.message, 'error');
            }
        })
      }
    }else if(this.validno2===false){
      notify("Odaberite lokaciju/adresu!",'error');
    }else{
      notify("Neka polja nisu pravilno popunjena!",'error'); 
    }
  }

  odustani(){
    this.kontakt={};
    this.router.navigate(['kontakti/']);
  }

  public handleAddressChange(address: Address) {
    this.formattedAddress = address.formatted_address;
    // this.mapMarkers.push({ location: address.geometry.viewport});

  let lokacija = {lat:address.geometry.location.lat(),lng: address.geometry.location.lng()}
  this.mapMarkers=[];
  this.mapMarkers.push({ location: lokacija });
  //console.log(this.mapMarkers,lokacija)
  }
  options = {
    componentRestricions : {
      country: ['HR'],
      fields:['place_id','geometry','name', 'street_number'],
    }
  };

}