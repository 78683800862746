<h2 style="text-align: left ;margin-left: 40px;">Grobno mjesto</h2> 

<div class="content-block dx-card">
    <div class="responsive-paddings" style="margin-left:75px; width: 75%;">
        <dx-load-panel
            #loadPanel
            shadingColor="rgba(0,0,0,0.4)"
            [(visible)]="loadingVisible"
            [showIndicator]="true"
            [showPane]="true"
            [shading]="true"
            [closeOnOutsideClick]="false">
        </dx-load-panel>
        <dx-form
            labelLocation="top"
            [(formData)]="grobno_mjesto"
            [showColonAfterLabel]="true"
            validationGroup="forma"
            (onContentReady)="onContentReady($event)"
            >
            <dxi-item itemType="group" [colCount]="2">

                <dxi-item dataField="gr_brojgroba" [label]="{text: 'Broj groba'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined', width:'100px'}">
                        <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                </dxi-item>
            
                <dxi-item itemType="empty"></dxi-item>

                <dxi-item dataField="gr_ime" [label]="{text: 'Ime i prezime'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined' }">
                        <dxi-validation-rule  type="required" message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="gr_imeoca" [label]="{text: 'Ime oca'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined'}">
                </dxi-item>

                <dxi-item dataField="gr_datumukopa" [label]="{text: 'Datum ukopa'}" editorType="dxDateBox" 
                [editorOptions]="{ stylingMode:'outlined'}">
                </dxi-item>

                <dxi-item dataField="gr_jmbg" [label]="{text: 'JMBG'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined' }">
                </dxi-item>

            </dxi-item>

            <dxi-item itemType="group" [colCount]="3">

                <dxi-item dataField="gr_nazivgroblja" [label]="{text: 'Naziv groblja'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined' }">
                </dxi-item>

                <dxi-item dataField="gr_parcela" [label]="{text: 'Parcela'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined' }">
                </dxi-item>

                <dxi-item dataField="gr_polje" [label]="{text: 'Polje'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined' }">
                </dxi-item>

                <dxi-item dataField="gr_razred" [label]="{text: 'Razred'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined' }">
                </dxi-item>

                <dxi-item dataField="gr_red" [label]="{text: 'Red'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined' }">
                </dxi-item>

                <dxi-item dataField="gr_vrstagrmjesta" [label]="{text: 'Vrsta grobnog mjesta'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined' }">
                </dxi-item>

            </dxi-item>

                <dxi-item>
                    <dx-button 
                        validationGroup="forma"
                        type="success"
                        (onClick)="spremi(grobno_mjesto)"
                        >SPREMI
                    </dx-button>

                    <dx-button 
                        validationGroup="forma"
                        type="danger"
                        style="margin-left: 10px ;"
                        (onClick)="odustani()"
                        >ODUSTANI
                    </dx-button>
                </dxi-item>
        
        </dx-form>
    </div>
</div>