<div class="content-block">
    <dx-load-panel
        #loadPanel
        shadingColor="rgba(0,0,0,0.4)"
        [(visible)]="loadingVisible"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [closeOnOutsideClick]="false">
    </dx-load-panel>
    <dx-data-grid 
                #gridcontainer
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                [hoverStateEnabled]="true"
                [wordWrapEnabled]="true"
                [allowColumnReordering]="false"
                [allowColumnResizing]= "true"
                [showBorders]="true" 
                [showRowLines]="true" 
                [focusedRowEnabled]="true"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                [autoNavigateToFocusedRow]="false"
                id="gridcontainerid"
                keyExpr="idkorisnik"
                [showBorders]="true" 
                class="dx-card wide-card"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                [dataSource]="korisnici"
                (onInitNewRow)="onInitNewRow($event)"
                 >

                <dxo-search-panel [visible]="true" placeholder="Pretraži..." ></dxo-search-panel>
                <dxo-paging [pageSize]="15"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[30, 60, 90]" [showInfo]="true" [visible]="true"></dxo-pager>
                

                <dxi-column dataField="kor_ime" caption="Ime i prezime" alignment="left"  width="100px" dataType="string"></dxi-column>
                <dxi-column dataField="od_naziv" caption="Naziv odjela" alignment="left"   dataType="string"></dxi-column>
                <dxi-column dataField="kor_email" caption="Mail" alignment="left"  dataType="string"></dxi-column>
                <dxi-column dataField="kor_telefon" caption="Telefon" alignment="left" dataType="number"></dxi-column>
                <dxi-column dataField="kor_admin" caption="Admin" alignment="center" cellTemplate="statusAdmin"></dxi-column>

                <dxi-column  type="buttons" alignment="center" ><dxi-button template="buttonTemplate" ></dxi-button></dxi-column>
                <div *dxTemplate="let buttonInfo of 'buttonTemplate'" class="ikona"> 
                    <dx-button icon="edit" id="btn"  (click)="uredi(buttonInfo)"></dx-button>
                </div>  

                <div *dxTemplate="let data of 'statusAdmin'">
                    <div *ngIf="data.data.kor_admin==1" >
                    <i class="dx-icon-check"></i>
                    </div>
                </div> 

                <div *dxTemplate="let data of 'dodaj'">
                    <dx-button icon="add" hint="Dodavanje korisnika" (onClick)="onInitNewRow($event)"></dx-button>
                </div>
                <div *dxTemplate="let data of 'naslovTablice'">
                    <div class="naslov">
                        <p class="name">Korisnici</p>
                    </div>
                </div>

    </dx-data-grid>
</div>    