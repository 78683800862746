import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-pocetna',
  templateUrl: './pocetna.component.html',
  styleUrls: ['./pocetna.component.scss']
})
export class PocetnaComponent implements OnInit {

  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  loadingVisible = true;
  prijave: any;
  obavijesti: any;
  dogadaji: any;
  danasDatum:Date=new Date();
  prviDan:Date; 
  zadnjiDan:Date;
  datumiPrijave:any[]=[];
  brprijave=0;
  datumiDogadjaja:any[]=[];
  brdogadaj=0;
  datumiObavijesti:any[]=[];
  brobavijesti=0;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private api: ApiService
    ) {
      this.token = sessionStorage.getItem('token');
      this.nivoPrava=sessionStorage.getItem('nivoPrava');
      this.idkorisnik=sessionStorage.getItem('idkorisnik');
      this.kor_ime=sessionStorage.getItem('kor_ime');
      this.kor_prezime=sessionStorage.getItem('kor_prezime');
      this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
      this.prviDan=new Date(this.danasDatum.getFullYear(), this.danasDatum.getMonth(), 1);
      this.zadnjiDan = new Date(this.danasDatum.getFullYear(), this.danasDatum.getMonth() + 1, 0);
    }
  ngOnInit(): void {
    this.dohvatiPrijave();
    this.dohvatiObavijesti();
    this.dohvatiDogadjaje();
  }

  dohvatiPrijave(){
    let podaci = {
      access_token: this.token,
      idkorisnik:this.idkorisnik
    }
    this.api.dohvatiPrijave(podaci).subscribe(res => {
      this.prijave = res.data;  
        for(let i=0;i<this.prijave.length;i++){
          if(this.prijave[i].pr_status==1){
            this.datumiPrijave.push(new Date(this.prijave[i].pr_datum))
          }}
        for(let i=0;i<this.datumiPrijave.length;i++){
          if(this.datumiPrijave[i]>=this.prviDan && this.datumiPrijave[i]<=this.zadnjiDan){
            this.brprijave++;
          }
        }
        this.loadingVisible = false;
    })
  }

  dohvatiObavijesti(){
    let podaci = {
      access_token: this.token,
    }
    this.api.dohvatiObavijesti(podaci).subscribe(res => {
      this.loadingVisible = false;
      this.obavijesti = res.data;   
      for(let i=0;i<this.obavijesti.length;i++){
        if(this.obavijesti[i].ob_aktivna==1){
          this.datumiObavijesti.push(new Date(this.obavijesti[i].ob_datum))
        }
      }
      for(let i=0;i<this.datumiObavijesti.length;i++){
          if(this.datumiObavijesti[i]>=this.prviDan && this.datumiObavijesti[i]<=this.zadnjiDan){
            this.brobavijesti++;
          }
      }
      this.loadingVisible = false;
    })
  }

  dohvatiDogadjaje(){
    let podaci = {
      access_token: this.token,
    }
    this.api.dohvatiDogadaje(podaci).subscribe(res => {
      this.loadingVisible = false;
      this.dogadaji = res.data;
      for(let i=0;i<this.dogadaji.length;i++){
        if(this.dogadaji[i].do_aktivan==1){
          this.datumiDogadjaja.push(new Date(this.dogadaji[i].do_datum))
        }
      }
      for(let i=0;i<this.datumiDogadjaja.length;i++){
          if(this.datumiDogadjaja[i]>=this.prviDan && this.datumiDogadjaja[i]<=this.zadnjiDan){
          this.brdogadaj++;
          }
      }
      this.loadingVisible = false;
    })
  }

}