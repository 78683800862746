<h2 style="text-align: left ;margin-left: 40px;">Problem</h2> 
<div class="content-block dx-card">
    <div class="responsive-paddings" style="margin-left:75px;">
    <dx-load-panel
        #loadPanel
        shadingColor="rgba(0,0,0,0.4)"
        [(visible)]="loadingVisible"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [closeOnOutsideClick]="false">
    </dx-load-panel>
        <dx-form
            labelLocation="top"
            [(formData)]="problem"
            [showColonAfterLabel]="true"
            validationGroup="forma"
            (onContentReady)="onContentReady($event)"
            >
            
                <dxi-item dataField="pr_naziv" [label]="{text: 'Naziv problema'}" editorType="dxTextBox" 
                [editorOptions]="{ stylingMode:'outlined', width:'400px' }">
                        <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                </dxi-item>

        </dx-form>

    <dx-data-grid 
                #gridcontainer
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                [hoverStateEnabled]="true"
                [wordWrapEnabled]="true"
                [allowColumnReordering]="false"
                [allowColumnResizing]= "true"
                [showBorders]="true" 
                [showRowLines]="true" 
                [focusedRowEnabled]="true"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                [autoNavigateToFocusedRow]="false"
                id="gridcontainerid"
                [showBorders]="true" 
                class="dx-card wide-card"
                keyExpr="kor_ime"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                [dataSource]="zaduzeni"
                style="width:40%;"
                 >
                <dxi-column dataField="kor_ime" caption="Ime" alignment="left" dataType="string"></dxi-column>
                <dxi-column dataField="kor_prezime" caption="Prezime" alignment="left" dataType="string"></dxi-column>

                 <dxi-column  type="buttons" alignment="center" ><dxi-button template="buttonTemplate" ></dxi-button></dxi-column>
                <div *dxTemplate="let buttonInfo of 'buttonTemplate'" class="ikona"> 
                    <dx-button icon="trash" id="btn"  (click)="brisanje(buttonInfo)"></dx-button>
                </div>  

                <div *dxTemplate="let data of 'dodaj'">
                    <dx-button icon="add" hint="Dodavanje zaduženih osoba" (onClick)="onInitNewRow($event)"></dx-button>
                </div>

                <div *dxTemplate="let data of 'naslovTablice'">
                    <div class="naslov">
                        <p class="name">Zadužene osobe</p>
                    </div>
                </div>
    </dx-data-grid>
                    <dx-button 
                        validationGroup="forma"
                        type="success"
                        (onClick)="spremi(problem)"
                        >SPREMI
                    </dx-button>

                    <dx-button 
                        validationGroup="forma"
                        type="danger"
                        style="margin-left: 10px ;"
                        (onClick)="odustani()"
                        >ODUSTANI
                    </dx-button>
    </div>
</div>
<!-- -------------------POPUP ZA AUTOCOMPLETE ---------------------- -->
<dx-popup
[closeOnOutsideClick]="false"
[showCloseButton]="true"
[(visible)]="popupAutocompleteVisible"
[width]="400"
[height]="250"
title="Zadužene osobe"
>

    <dx-form
    labelLocation="top"
    (formData)="korisnici"
    [showColonAfterLabel]="true"
>

            <dxi-item [label]="{text: 'Zadužene osobe'}" dataField="kor_ime">
                <div *dxTemplate="let data of 'zaduzeni'">
                    <dx-autocomplete #autocompleteZaduzeni 
                    [searchExpr]="['kor_ime']"
                    class="select"          
                    [minSearchLength]="1"   
                    stylingMode= 'outlined' 
                    [dataSource]="korisnici"
                    [showClearButton]="true"
                    valueExpr='kor_ime'        
                    itemTemplate="lookupItem"
                    (onItemClick)="zaduzeniClick($event)"
                    >
                    <div *dxTemplate="let item of 'lookupItem'; let i = index">
                        {{item.kor_ime}}
                    </div>
                    </dx-autocomplete>     
                 </div>
             
            </dxi-item> 
    </dx-form>
                    <dx-button 
                        type="danger"
                        style="float: right;"
                        (onClick)="zatvoriPopup()"
                        >ODUSTANI
                    </dx-button>
</dx-popup>
