import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

import notify from 'devextreme/ui/notify';
import validationEngine from 'devextreme/ui/validation_engine';

import * as moment from 'moment';

@Component({
  selector: 'app-grobno-mjesto',
  templateUrl: './grobno-mjesto.component.html',
  styleUrls: ['./grobno-mjesto.component.scss']
})
export class GrobnoMjestoComponent implements OnInit {

  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  grobID:any;
  grobno_mjesto:any={};
  aktivna:boolean=true;
  validacijaForma: any;
  validno: boolean=false;
  loadingVisible:boolean=false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private api: ApiService,
    private route: ActivatedRoute
  ) {
    if(isNaN(this.route.snapshot.params['id']) || this.route.snapshot.params['id'] === null || this.route.snapshot.params['id'] === undefined ){
      this.grobID = null;
    }else{
      this.grobID = +this.route.snapshot.params['id'];
    }
    this.token = sessionStorage.getItem('token');
    this.nivoPrava=sessionStorage.getItem('nivoPrava');
    this.idkorisnik=sessionStorage.getItem('idkorisnik');
    this.kor_ime=sessionStorage.getItem('kor_ime');
    this.kor_prezime=sessionStorage.getItem('kor_prezime');
    this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
   }

  ngOnInit(): void {
    if(this.grobID!=undefined && this.grobID !=null ){
      this.loadingVisible=true;
      this.dohvatiGrobnoMjesto();
    }
  }

  onContentReady(e){
    this.validacijaForma = validationEngine.validateGroup("forma");
  }

  dohvatiGrobnoMjesto(){
    let podaci={
      idgrob:this.grobID,
      access_token:this.token
    }
    this.api.dohvatiJedanGrob(podaci).subscribe(res => {
       this.grobno_mjesto=res.data[0];
       setTimeout(() => { 
        this.loadingVisible=false;
        }, 500)
    })
  }

  spremi(e){
    //PROVJERAVANJE VALIDNOSTI SVAKOG PRAVILA
    let br=0;
    for(let i=0;i<this.validacijaForma.brokenRules.length;i++){
      if(this.validacijaForma.brokenRules[i].isValid==true){
        br++;
      }
    }if(br==this.validacijaForma.brokenRules.length){
      this.validno=true;
    }else{
      this.validno=false;
    }
    if(this.validno===true){
      if(this.grobID == undefined || this.grobID == null  ){ //DODAVANJE NOVOG GROBNOG MJESTA
        let podaci = {
          ime: e.gr_ime,
          access_token: this.token,
          datumukopa:moment(e.gr_datumukopa).format('YYYY-MM-DD'),
          imeoca:e.gr_imeoca,
          jmbg:e.gr_jmbg,
          parcela:e.gr_parcela,
          polje:e.gr_polje,
          red:e.gr_red,
          brgroba:e.gr_brojgroba,
          razred:e.gr_razred,
          nazivgr:e.gr_nazivgroblja,
          vrstagrmj:e.gr_vrstagrmjesta,
        }
        this.api.dodajGrob(podaci).subscribe(res => {
          if(res.success === true){
            notify(res.message, 'success');
            this.router.navigate(['grobna-mjesta/']); 
          } else {
            notify(res.message, 'error');
          }
        })
      }else{                   //UREĐIVANJE GROBNOG MJESTA
        let podaci = {
          idgrob:this.grobID,
          ime: e.gr_ime,
          access_token: this.token,
          datumukopa:moment(e.gr_datumukopa).format('YYYY-MM-DD'),
          imeoca:e.gr_imeoca,
          jmbg:e.gr_jmbg,
          parcela:e.gr_parcela,
          polje:e.gr_polje,
          red:e.gr_red,
          brgroba:e.gr_brojgroba,
          razred:e.gr_razred,
          nazivgr:e.gr_nazivgroblja,
          vrstagrmj:e.gr_vrstagrmjesta,
        }
        this.api.urediGrob(podaci).subscribe(res => {
          if(res.success === true){
            notify(res.message, 'success');
            this.router.navigate(['grobna-mjesta/']); 
          } else {
            notify(res.message, 'error');
          }
        })
      }
    }else{
      notify("Neka polja nisu pravilno popunjena!",'error');
    }
  }
  
  odustani(){
    this.grobno_mjesto={};
    this.router.navigate(['grobna-mjesta/']);
  }

}