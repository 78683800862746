<div class="content-block">
    <dx-load-panel
        #loadPanel
        shadingColor="rgba(0,0,0,0.4)"
        [(visible)]="loadingVisible"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [closeOnOutsideClick]="false">
    </dx-load-panel>
    <dx-data-grid 
                #gridcontainer
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                [hoverStateEnabled]="true"
                [wordWrapEnabled]="true"
                [allowColumnReordering]="false"
                [allowColumnResizing]= "true"
                [showBorders]="true" 
                [showRowLines]="true" 
                [focusedRowEnabled]="true"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                [autoNavigateToFocusedRow]="false"
                id="gridcontainerid"
                keyExpr="idodjel"
                [showBorders]="true" 
                class="dx-card wide-card"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                [dataSource]="odjeli"
                (onRowInserted)="spremi($event)"
                (onInitNewRow)="onInitNewRow($event)"
                (onEditingStart)="jedanOdjel($event)"
                (onRowUpdated)="urediOdjel($event)"
                (onRowRemoved)="obrisiOdjel($event)"
                 >

                <dxo-search-panel [visible]="true" placeholder="Pretraži..." ></dxo-search-panel>
                <dxo-paging [pageSize]="15"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[30, 60, 90]" [showInfo]="true" [visible]="true"></dxo-pager>
                
                <dxo-editing
                 mode="popup"
                 [allowUpdating]="true"
                 [allowDeleting]="true"
                 [allowAdding]="true">    
                <dxo-popup  
                    [onShowing]="onShowing"
                    title= ""
                    [showTitle]="true"
                    [width]="600"
                    [height]="'auto'">
                </dxo-popup> 

                <dxo-form>
                    <dxi-item dataField="od_oznaka" [label]="{text: 'Oznaka odjela'}" [editorOptions]="{ stylingMode:'outlined'}">
                        <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                    </dxi-item>   
                    <dxi-item dataField="od_naziv" [label]="{text: 'Naziv odjela'}" [editorOptions]="{ stylingMode:'outlined'}">
                        <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                    </dxi-item> 
                </dxo-form> 
            </dxo-editing>      

                <dxi-column dataField="od_oznaka" caption="Oznaka odjela" alignment="center"  width="250px"></dxi-column>
                <dxi-column dataField="od_naziv" caption="Naziv odjela" alignment="left" dataType="string"></dxi-column>

                <div *dxTemplate="let data of 'naslovTablice'">
                    <div class="naslov">
                        <p class="name">Odjeli</p>
                    </div>
                </div>

    </dx-data-grid>
</div>    