import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/shared/services/api.service';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-obavijesti',
  templateUrl: './obavijesti.component.html',
  styleUrls: ['./obavijesti.component.scss']
})
export class ObavijestiComponent implements OnInit {

  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  row:any;
  loadingVisible = true;
  obavijesti: any;
  obrisiPopupVisible: boolean=false;
  idZaObrisat: any;
  samoPregled: boolean=false;

  constructor(
    private router: Router,
    private api: ApiService
    ) {
      this.token = sessionStorage.getItem('token');
      this.nivoPrava=sessionStorage.getItem('nivoPrava');
      this.idkorisnik=sessionStorage.getItem('idkorisnik');
      this.kor_ime=sessionStorage.getItem('kor_ime');
      this.kor_prezime=sessionStorage.getItem('kor_prezime');
      this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
      if(this.nivoPrava==0){
        this.samoPregled=true;
      }
    }

  ngOnInit(): void {
    this.dohvatiObavijesti();
  }

  onToolbarPreparing(e: any) { 
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'naslovTablice'   
      },
      {
        location: 'after',
        template: "dodaj",
      },
    )
  }

  onFocusedRowChanged(e) {
    this.row=e.row;
  }

  onInitNewRow(e){
    this.router.navigate(['obavijesti/dodaj-obavijest/']);
  }

  dohvatiObavijesti(){
    let podaci = {
      access_token: this.token,
    }
    this.api.dohvatiObavijesti(podaci).subscribe(res => {
      this.loadingVisible = false;
      this.obavijesti = res.data;   
    })
  }

  uredi(e){
      this.router.navigate(['obavijesti/uredi-obavijest/' + e.data.idobavijest]);  
  }

  brisanje(e){
    this.idZaObrisat=e.data.idobavijest
    this.obrisiPopupVisible=true;
  }
  
  obrisi(){
    if(this.samoPregled==false){
      let podaci = {
        access_token: this.token,
        idkorisnik: this.idkorisnik,
        idobavijest:this.idZaObrisat
      }
      this.api.obrisiObavijest(podaci).subscribe(res => {
          if(res.success){
            notify(res.message,'success');
            this.obrisiPopupVisible=false;
          }else{
            notify(res.message,'error');
            this.obrisiPopupVisible=false;
          }
          this.dohvatiObavijesti()
      })
    }else{
      notify("Nemate administratorska prava za brisanje!",'error');
    }
  }

  odustani(){
    this.obrisiPopupVisible=false;
  }

}