import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import notify from 'devextreme/ui/notify';
import dxGallery from 'devextreme/ui/gallery';

import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-dogadjanja',
  templateUrl: './dogadjanja.component.html',
  styleUrls: ['./dogadjanja.component.scss']
})
export class DogadjanjaComponent implements OnInit {

  @ViewChild('gallery') gallery: dxGallery;
  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  row:any;
  loadingVisible = true;
  dogadaji:any;
  idZaObrisat: any;
  obrisiPopupVisible: boolean=false;
  slikeZaPrikaz: any;
  slike: any;
  popupImageVisible: boolean=false;
  samoPregled:boolean=false;

  constructor(
    private router: Router,
    private api: ApiService
    ) {
      this.token = sessionStorage.getItem('token');
      this.nivoPrava=sessionStorage.getItem('nivoPrava');
      this.idkorisnik=sessionStorage.getItem('idkorisnik');
      this.kor_ime=sessionStorage.getItem('kor_ime');
      this.kor_prezime=sessionStorage.getItem('kor_prezime');
      this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
      if(this.nivoPrava==0){
        this.samoPregled=true;
      }
    }

  ngOnInit(): void {
    this.dohvatiDogadaje();
  }

  onToolbarPreparing(e: any) { 
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'naslovTablice'   
      },
      {
        location: 'after',
        template: "dodaj",
      },
    )
  }

  onFocusedRowChanged(e) {
    this.row=e.row;
  }

  onInitNewRow(e){
    this.router.navigate(['dogadjanja/dodaj-dogadaj/']);  
  }

  dohvatiDogadaje(){
    let podaci = {
      access_token: this.token,
    }
    this.api.dohvatiDogadaje(podaci).subscribe(res => {
      if(res.success==true){
      this.loadingVisible = false;
      this.dogadaji = res.data;
      } else{
      this.loadingVisible = false;
        notify(res.message, 'error');
      }
    })
  }

  uredi(e){ 
    this.router.navigate(['dogadjanja/uredi-dogadaj/' + e.data.iddogadjaj]);  
  }

  brisanje(e){
    this.idZaObrisat=e.key;
    this.obrisiPopupVisible=true;
  }

  obrisi(){
    if(this.samoPregled===false){
    this.obrisiPopupVisible=false;
    let podaci = {
      iddogadjaj: this.idZaObrisat,
      access_token: this.token,
      idkorisnik: this.idkorisnik
    }
    this.api.obrisiDogadaj(podaci).subscribe(res => {
        if(res.success){
          notify(res.message,'success');
          this.obrisiPopupVisible=false;
        }else{
          notify(res.message,'error');
          this.obrisiPopupVisible=false;
        }
        this.dohvatiDogadaje()
      })
    }else{
      notify("Nemate administratorska prava za brisanje!",'error');
    }
  }

  odustani(){
    this.obrisiPopupVisible=false;
  }

  openImage(e){
    if(e.data.slike.length>0){
      this.slike=[]
      for(let i=0;i<e.data.slike.length;i++){
        this.slike.push(e.data.slike[i].putanja)
        this.slike.join(",")
      }
      this.popupImageVisible=true;
    }else{
      notify("Odabrano događanje nema fotografija!", 'info');
    }
  }

  galerija(e){
    e.component.goToItem(0,0);
  }

}