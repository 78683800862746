import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

import notify from 'devextreme/ui/notify';
import validationEngine from 'devextreme/ui/validation_engine';
import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';

@Component({
  selector: 'app-korisnik',
  templateUrl: './korisnik.component.html',
  styleUrls: ['./korisnik.component.scss']
})
export class KorisnikComponent implements OnInit {

  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  korisnikID:any;
  korisnik:any={};
  aktivna:boolean=true;
  validacijaForma: any;
  validno: boolean=false;
  loadingVisible:boolean=false;
  idodjel: any;
  odjeli:any;
  passwordMode: string = "password";
  password: string;
  admin: number;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private api: ApiService,
    private route: ActivatedRoute
  ) {
    if(isNaN(this.route.snapshot.params['id']) || this.route.snapshot.params['id'] === null || this.route.snapshot.params['id'] === undefined ){
      this.korisnikID = null;
    }else{
      this.korisnikID = +this.route.snapshot.params['id'];
    }
    this.token = sessionStorage.getItem('token');
    this.nivoPrava=sessionStorage.getItem('nivoPrava');
    this.idkorisnik=sessionStorage.getItem('idkorisnik');
    this.kor_ime=sessionStorage.getItem('kor_ime');
    this.kor_prezime=sessionStorage.getItem('kor_prezime');
    this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
    this.odjelClick = this.odjelClick.bind(this);
   }

  ngOnInit(): void {
    if(this.korisnikID!=undefined && this.korisnikID !=null ){
      this.loadingVisible=true;
      this.dohvatiKorisnika();
    }
    this.dohvatiOdjele();
  }

  onContentReady(e){
    this.validacijaForma = validationEngine.validateGroup("forma");
  }

  odjelClick(e){
    this.idodjel = e.itemData.idodjel;
  }

  displayOdjel(e){
    if(e){
      return e.od_naziv;
    } else {
      return '';
    }
  }

  dohvatiKorisnika(){
    let podaci={
      idkorisnik:this.korisnikID,
      access_token:this.token
    }
    this.api.dohvatiJednogKorisnika(podaci).subscribe(res => {
         this.korisnik=res.data[0];
         let imeiprezime=this.korisnik.kor_ime.split(" ",2);
         this.korisnik.kor_ime=imeiprezime[0];
         this.korisnik.kor_prezime=imeiprezime[1];
         this.idodjel=this.korisnik.odjel_idodjel;
         setTimeout(() => { 
            this.loadingVisible=false;
         }, 500)
    })
  }

  dohvatiOdjele(){
    let podaci={
      access_token:this.token
    }
    this.api.dohvatiOdjele(podaci).subscribe(res => {
         this.odjeli=res.data;
    })
  }


  spremi(e){
    //PROVJERAVANJE VALIDNOSTI SVAKOG PRAVILA
    let br=0;
    for(let i=0;i<this.validacijaForma.brokenRules.length;i++){
      if(this.validacijaForma.brokenRules[i].isValid==true){
        br++;
      }
    }if(br==this.validacijaForma.brokenRules.length){
      this.validno=true;
    }else{
      this.validno=false;
    }
    if(this.validno===true){
    if(this.korisnikID == undefined || this.korisnikID == null  ){ //DODAVANJE NOVOG KORISNIKA
      let podaci = { 
        access_token: this.token,
        ime: e.kor_ime,
        prezime:e.kor_prezime,
        lozinka: e.kor_lozinka,	
        idodjel:this.idodjel,
        email:e.kor_email,
        telefon:e.kor_telefon,
        admin:e.kor_admin,	
      } 
      
      this.api.dodajKorisnika(podaci).subscribe(res => { 
          if(res.success === true){
            notify(res.message, 'success');
            this.router.navigate(['korisnici/']); 
          } else {
            notify(res.message, 'error');
          }      
      })
    }else{                   //UREĐIVANJE KORISNIKA
      let podaci = { 
        idkorisnik:this.korisnikID,
        access_token: this.token,
        ime: e.kor_ime,
        prezime:e.kor_prezime,
        lozinka: e.kor_lozinka,	
        idodjel:this.idodjel,
        email:e.kor_email,
        telefon:e.kor_telefon,
        admin:e.kor_admin,
        aktivan:e.kor_aktivan,
      }   
      this.api.urediKorisnika(podaci).subscribe(res => {  
          if(res.success === true){
            notify(res.message, 'success');
            this.router.navigate(['korisnici/']); 
          } else {
            notify(res.message, 'error');
          }      
      })
    }
  }else{
    notify("Neka polja nisu pravilno popunjena!",'error');
  }
}

  odustani(){
    this.korisnik={};
    this.router.navigate(['korisnici/']);
  }

  otkrijLozinku(){
    this.passwordMode = this.passwordMode === "text" ? "password" : "text";
  }

}