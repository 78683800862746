import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

import { DxMapComponent } from 'devextreme-angular';
import validationEngine from 'devextreme/ui/validation_engine';
import notify from 'devextreme/ui/notify';

import * as moment from 'moment';

import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';

import { MapsAPILoader } from '@agm/core';


class ImageSnippet{
  pending: boolean;
  status: string;
  constructor(public src:string, public file: File){}
}

@Component({
  selector: 'app-prijava',
  templateUrl: './prijava.component.html',
  styleUrls: ['./prijava.component.scss']
})
export class PrijavaComponent implements OnInit {

@ViewChild('inputData') someInput: ElementRef;
@ViewChild("placesRef") placesRef : GooglePlaceDirective;
@ViewChild(DxMapComponent)mapa : DxMapComponent
  mapMarkers = [];
  validno2: boolean=true;
  formattedAddress = '';
  token:any;
  idkorisnik:any;
  idgost:any
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  window: any;
  prijavaID:any;
  prijava:any={};
  validacijaForma: any;
  validno: boolean=false;
  selectedFile: ImageSnippet;
  popupImageVisible: boolean=false;
  slikaZaPrikaz: string[];
  slika: any=[];
  vrste:any;
  status:any=[
    {id:1, naziv:"Zaprimljeno"},
    {id:2, naziv:"U postupku"},
    {id:3, naziv:"Riješeno"}
  ];
  loadingVisible:boolean=false;
  blokiranjeZaSlanje: any;
  keys={};
  latitude:number=45.116733;
  longitude:number=18.537016;
  zoom: number = 18;

  private geoCoder= new google.maps.Geocoder();
  slikaZaSlanje: any=[];

constructor(
  private router: Router,
  private authService: AuthService,
  private api: ApiService,
  private route: ActivatedRoute,
  private _mapsAPILoader: MapsAPILoader,
) {
  if(isNaN(this.route.snapshot.params['id']) || this.route.snapshot.params['id'] === null || this.route.snapshot.params['id'] === undefined ){
    this.prijavaID = null;
  }else{
    this.prijavaID = +this.route.snapshot.params['id'];
  }
  this.token = sessionStorage.getItem('token');
  this.nivoPrava=sessionStorage.getItem('nivoPrava');
  this.idkorisnik=sessionStorage.getItem('idkorisnik');
  this.kor_ime=sessionStorage.getItem('kor_ime');
  this.kor_prezime=sessionStorage.getItem('kor_prezime');
  this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
  this.idgost=sessionStorage.getItem('idgost');

  this.keys["google"]="AIzaSyAInEYFG0_ykzrvxDU8Qmf7c39MRO_qXCk";
  
  this.getAddress = this.getAddress.bind(this);
 }

  ngOnInit(): void {
    if(this.prijavaID!=undefined && this.prijavaID !=null ){
      this.loadingVisible=true;
      this.dohvatiPrijavu();
    }
    this.dohvatiVrsteProblema();
  }

  onContentReady(e){
    this.validacijaForma = validationEngine.validateGroup("forma");
  }

  dohvatiPrijavu(){
    let podaci={
      access_token: this.token,
      idprijava:this.prijavaID
    }
    this.api.dohvatiJednuPrijavu(podaci).subscribe(res => {
        this.prijava=res.data[0];
        this.idgost=this.prijava.gost_idgost;
        this.formattedAddress=this.prijava.pr_adresa;
        let lokacija = {lat:this.prijava.pr_lat,lng: this.prijava.pr_lon}
        this.mapMarkers=[]
        this.mapMarkers.push({ location: lokacija });
        this.longitude=this.prijava.pr_lon;
        this.latitude=this.prijava.pr_lat;
        this.dodajMarker(this.mapa.instance);
        if(res.data[0].slike[0]!=undefined){
            this.slika[0]=res.data[0].slike[0].putanja;
            this.slikaZaSlanje[0]=res.data[0].slike[0]
        }else{
          this.slika=[]
        }
        setTimeout(() => { 
          this.loadingVisible=false;
          }, 500)
    })
}
  dohvatiVrsteProblema(){
    let podaci={
      access_token:this.token
    }
    this.api.dohvatiVrsteProblema(podaci).subscribe(res => {
        this.vrste=res.data;
        if(this.prijavaID==null || this.prijavaID==undefined){
          this.dodajMarker(this.mapa.instance);
        }
    })
}
  spremi(e){
    let formData=new FormData;
    //PROVJERAVANJE VALIDNOSTI SVAKOG PRAVILA
    let br=0;
    for(let i=0;i<this.validacijaForma.brokenRules.length;i++){
      if(this.validacijaForma.brokenRules[i].isValid==true){
        br++;
      }
    }if(br==this.validacijaForma.brokenRules.length){
      this.validno=true;
    }else{
      this.validno=false;
    }
    if(this.mapMarkers[0]==undefined || null){
      this.validno2=false;
    }else{
      this.validno2=true;
    }
    if(this.validno===true && this.validno2===true){
      if(this.prijavaID == undefined || this.prijavaID == null  ){

        if(e.kor_blokiranje==true){
          this.blokiranjeZaSlanje=1;
        }else{
          this.blokiranjeZaSlanje=0;
        }
        if(this.slika[0]!=undefined || null){
          formData.append('datoteka',this.slikaZaSlanje[0].file)
        }else{
          formData.append('datoteka',this.slikaZaSlanje[0])
        }
        formData.append('datum',moment(e.pr_datum).format('YYYY-MM-DD'));
        formData.append('naziv',e.pr_naziv);
        formData.append('opis',e.pr_opis);
        formData.append('blokiranje',this.blokiranjeZaSlanje);
        formData.append('idkorisnik',this.idkorisnik);
        formData.append('access_token',this.token);
        formData.append('lat',this.mapMarkers[0].location.lat);
        formData.append('lon',this.mapMarkers[0].location.lng);
        formData.append('idvrsta',e.vrsta_idvrsta);
        formData.append('idgost',this.idgost);
        formData.append('adresa',this.formattedAddress);

        this.api.dodajPrijavu(formData).subscribe(res => {
        //console.log(res); 
        if(res.success === true){
          notify(res.message, 'success');
          this.router.navigate(['prijave/']); 
        } else {
          notify(res.message, 'error');
        }
        })
      }else{
        let podaci = {
          idkorisnik: this.idkorisnik,
          access_token: this.token,
          idprijava:this.prijavaID,
          naziv:e.pr_naziv,
          opis:e.pr_opis,
          lat:this.mapMarkers[0].location.lat,
          lon:this.mapMarkers[0].location.lng,
          adresa:this.formattedAddress,
          status:e.pr_status,
          blokiraj:e.kor_blokiranje,
          idgost:this.idgost,
          idvrsta:e.vrsta_idvrsta,
        }
        this.api.urediPrijavu(podaci).subscribe(res => {
        if(res.success === true){
          notify(res.message, 'success');
          this.router.navigate(['prijave/']); 
        } else {
          notify(res.message, 'error');
        }
        })
      }
  }else if(this.validno2===false){
    notify("Odaberite lokaciju/adresu!",'error');
  }else{
    notify("Neka polja nisu pravilno popunjena!",'error'); 
  }
}

  odustani(){
    this.prijava={};
    this.router.navigate(['prijave/']);   
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
        if(this.slika.length==0){
        this.slikaZaSlanje.unshift(new ImageSnippet(event.target.result, file));
        this.slika[0]=this.slikaZaSlanje[0].src;
      }else{
        notify("Možete odabrati samo jednu fotografiju!",'error');
      }
    });
    reader.readAsDataURL(file);
}
  remove(i){
    this.slika=[]
  }

  openImage(){
    this.slikaZaPrikaz=[];
    for(let i=0;i<this.slika.length;i++){
      this.slikaZaPrikaz.push(this.slika[i])
      this.slikaZaPrikaz.join(",");
    }
    this.popupImageVisible=true;
  }
  
  public handleAddressChange(address: Address) {
    this.formattedAddress = address.formatted_address;
    this.longitude=address.geometry.location.lng();
    this.latitude=address.geometry.location.lat();

   let lokacija = {lat:address.geometry.location.lat(),lng: address.geometry.location.lng()}
   this.mapMarkers=[];
   this.mapMarkers.push({ location: lokacija });
   this.obrisiMarker(this.mapa.instance)
   this.dodajMarker(this.mapa.instance);
}
options = {
  componentRestricions : {
    country: ['HR'],
    fields:['place_id','geometry','name', 'street_number'],
  }
};

getAddress(latitude, longitude) {
  this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
    if (status === 'OK') {
      if (results[0]) {
        this.zoom = 18 // 15;
        this.formattedAddress = results[0].formatted_address;
        this.prijava.pr_adresa=this.formattedAddress;
      } else {
        notify('No results found');
      }
    } else {
      notify('Geocoder failed due to: ' + status);
    }
  });
}

dodajMarker(e){
  let map = e;
  map
    .addMarker({
      location: [this.latitude, this.longitude],
    })
      .done((marker) => {
        marker.setDraggable(true);
        google.maps.event.addListener(marker, "dragend", (event) => {
          this.latitude=event.latLng.lat();
          this.longitude=event.latLng.lng();
          this.getAddress(this.latitude, this.longitude);
          let lokacija = {lat:this.latitude,lng: this.longitude};
          if(this.mapMarkers[0]!=undefined){
            this.mapMarkers.pop();
            this.mapMarkers.push({ location: lokacija });
          }else{
            this.mapMarkers.push({ location: lokacija });
          }
          
         });

      }); 
  }

  obrisiMarker(e){
    e.removeMarker(0)
  }

}
