import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/shared/services/api.service';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-korisnici',
  templateUrl: './korisnici.component.html',
  styleUrls: ['./korisnici.component.scss']
})
export class KorisniciComponent implements OnInit {

  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  row:any;
  loadingVisible = true;
  korisnici: any;
  odjeli:any;
  naslov: string;
  isEdited: boolean;

  constructor(
    private router: Router,
    private api: ApiService
    ) {
      this.token = sessionStorage.getItem('token');
      this.nivoPrava=sessionStorage.getItem('nivoPrava');
      this.idkorisnik=sessionStorage.getItem('idkorisnik');
      this.kor_ime=sessionStorage.getItem('kor_ime');
      this.kor_prezime=sessionStorage.getItem('kor_prezime');
      this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
      this.onShowing = this.onShowing.bind(this);
    }

  ngOnInit(): void {
    this.dohvatiKorisnike();
  }

  onToolbarPreparing(e: any) { 
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'naslovTablice'   
      },
      {
        location: 'after',
        template: "dodaj",
      }
    )
  }

  onShowing(e: any){
    e.component.option("title", this.naslov);
  }

  onFocusedRowChanged(e) {
    this.row=e.row;
  }

  dohvatiKorisnike(){
    let podaci = {
      access_token: this.token,
    }
    this.api.dohvatiKorisnike(podaci).subscribe(res => {
      this.loadingVisible = false;
      this.korisnici = res.data;   
    })
  }

  onInitNewRow(e){
    this.router.navigate(['korisnici/dodaj-korisnika/']);  
  }

  uredi(e){
    this.router.navigate(['korisnici/uredi-korisnika/' + e.data.idkorisnik]);  
  }

}