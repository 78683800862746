<h2 style="text-align: left ;margin-left: 40px;">Obavijest</h2> 

<div class="content-block dx-card">
    <div class="responsive-paddings" style="margin-left:75px;">
        <dx-load-panel
            #loadPanel
            shadingColor="rgba(0,0,0,0.4)"
            [(visible)]="loadingVisible"
            [showIndicator]="true"
            [showPane]="true"
            [shading]="true"
            [closeOnOutsideClick]="false">
        </dx-load-panel>
        <dx-form
            labelLocation="top"
            [(formData)]="obavijest"
            [showColonAfterLabel]="true"
            validationGroup="forma"
            (onContentReady)="onContentReady($event)"
            >
            
                <dxi-item dataField="ob_datum" [label]="{text: 'Datum'}" editorType="dxDateBox" 
                    [editorOptions]="{ stylingMode:'outlined', width:'200px', readOnly: samoPregled}">
                        <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                </dxi-item>
            
                <dxi-item dataField="ob_tekst" [label]="{text: 'Tekst obavijesti'}" editorType="dxTextArea" 
                    [editorOptions]="{ stylingMode:'outlined',height:'200px', width:'600px', readOnly: samoPregled }">
                        <dxi-validation-rule  type="required" message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="ob_web" [label]="{text: 'Web'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined', width:'500px' , readOnly: samoPregled}">
                    <dxi-validation-rule type="custom" [validationCallback]="validationCallback" message="Mora sadržavati https://"></dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="ob_aktivna" [label]="{text: 'Aktivna'}" editorType="dxCheckBox" cssClass="ikona" 
                    [editorOptions]="{ iconSize:'50px' , value:aktivna, readOnly: samoPregled}">
                </dxi-item>

                <dxi-item>
                    <dx-button 
                        validationGroup="forma"
                        type="success"
                        (onClick)="spremi(obavijest)"
                        >SPREMI
                    </dx-button>

                    <dx-button 
                        validationGroup="forma"
                        type="danger"
                        style="margin-left: 10px ;"
                        (onClick)="odustani()"
                        >ODUSTANI
                    </dx-button>
                </dxi-item>
        
        </dx-form>
    </div>
</div>