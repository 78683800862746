<div class="content-block">
    <dx-load-panel
        #loadPanel
        shadingColor="rgba(0,0,0,0.4)"
        [(visible)]="loadingVisible"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [closeOnOutsideClick]="false">
    </dx-load-panel>
    <dx-data-grid 
                #gridcontainer
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                [hoverStateEnabled]="true"
                [wordWrapEnabled]="true"
                [allowColumnReordering]="false"
                [allowColumnResizing]= "true"
                [showBorders]="true" 
                [showRowLines]="true" 
                [focusedRowEnabled]="true"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                [autoNavigateToFocusedRow]="false"
                id="gridcontainerid"
                keyExpr="idproblem"
                [showBorders]="true" 
                class="dx-card wide-card"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                [dataSource]="vrste_problema"
                 >

                 <dxo-search-panel [visible]="true" placeholder="Pretraži..." ></dxo-search-panel>
                 <dxo-paging [pageSize]="15"></dxo-paging>
                 <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[30, 60, 90]" [showInfo]="true" [visible]="true"></dxo-pager>
                 
                <dxi-column dataField="pr_naziv" caption="Vrsta problema" alignment="left"  dataType="string" width="350px"></dxi-column>

                <dxi-column dataField="zaduzeni" caption="Zaduženi" alignment="left" dataType="string" [calculateCellValue]="zaduzeni_calculateCellValue"></dxi-column>

                <dxi-column  type="buttons" alignment="center" ><dxi-button template="buttonTemplate" ></dxi-button></dxi-column>
                <div *dxTemplate="let buttonInfo of 'buttonTemplate'" class="ikona"> 
                    <dx-button icon="edit" id="btn"  (click)="uredi(buttonInfo)"></dx-button>
                    <dx-button icon="trash" id="btn"  (click)="brisanje(buttonInfo)"></dx-button>
                </div>  

                <div *dxTemplate="let data of 'dodaj'">
                    <dx-button icon="add" hint="Dodavanje obavijesti" (onClick)="onInitNewRow($event)"></dx-button>
                </div>

                <div *dxTemplate="let data of 'naslovTablice'">
                    <div class="naslov">
                        <p class="name">Vrste problema</p>
                    </div>
                </div>

    </dx-data-grid>
</div>    

<!-------------------------- Popup za potvrdu brisanja  ---------------->

<dx-popup
[closeOnOutsideClick]="false"
[showCloseButton]="true"
[(visible)]="obrisiPopupVisible"
[width]="400"
[height]="'auto'"
title="Brisanje vrste problema"
>
<p style="font-size: 16px; ">Jeste li sigurni da želite ukloniti odabranu vrstu problema? </p>
<p style="font-size: 16px; font-weight: bold;">Zadužene osobe : {{zaduzeniZaPrikaz}}</p>
<br>
<dx-button text="ODUSTANI" style="float:right;" (onClick)="odustani()"></dx-button>
<dx-button text="OBRIŠI" style="background-color:red; color:white; float:right;"  (onClick)="obrisi()"></dx-button>

</dx-popup>
