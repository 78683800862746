import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uredjaji',
  templateUrl: './uredjaji.component.html',
  styleUrls: ['./uredjaji.component.scss']
})
export class UredjajiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

}