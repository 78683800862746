import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

import notify from 'devextreme/ui/notify';

class ImageSnippet{
  pending: boolean;
  status: string;
  constructor(public src:string, public file: File){}
}

@Component({
  selector: 'app-grobna-mjesta',
  templateUrl: './grobna-mjesta.component.html',
  styleUrls: ['./grobna-mjesta.component.scss']
})
export class GrobnaMjestaComponent implements OnInit {

  @ViewChild('inputData') someInput: ElementRef;
  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  row:any;
  loadingVisible = true;
  groblje: any;
  obrisiPopupVisible: boolean=false;
  idZaObrisat: any;
  value: any;
  datoteka: any;
  imaXLS: boolean=false;
  karta: any;
  slika: any=[];
  popupImageVisible: boolean=false;
  slikaZaPrikaz: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private api: ApiService
    ) {
      this.token = sessionStorage.getItem('token');
      this.nivoPrava=sessionStorage.getItem('nivoPrava');
      this.idkorisnik=sessionStorage.getItem('idkorisnik');
      this.kor_ime=sessionStorage.getItem('kor_ime');
      this.kor_prezime=sessionStorage.getItem('kor_prezime');
      this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
    }

  ngOnInit(): void {
    this.dohvatiGroblje();
    this.dohvatiKartuGroblja();
  }

  onToolbarPreparing(e: any) { 
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'naslovTablice'   
      },
      {
        location: 'after',
        template: "dodaj",
      }
    )
  }

  onFocusedRowChanged(e) {
    this.row=e.row;
  }

  onInitNewRow(e){
    this.router.navigate(['grobna-mjesta/dodaj-grob/']);  
  }

  dohvatiGroblje(){
    let podaci = {
      access_token: this.token,
    }
    this.api.dohvatiGroblje(podaci).subscribe(res => {
      this.loadingVisible = false;
      this.groblje = res.data;   
    })
  }

  dohvatiKartuGroblja(){
    let podaci = {
      access_token: this.token,
    }
    this.api.dohvatiKartu(podaci).subscribe(res => {
      this.loadingVisible = false;
      this.slika = res.data[0].putanja;
    })
  }

  uredi(e){
    this.router.navigate(['grobna-mjesta/uredi-grob/' + e.data.idgrob]);  
  }

  brisanje(e){
    this.idZaObrisat=e.data.idgrob;
    this.obrisiPopupVisible=true;
  }

  obrisi(){
    let podaci = {
      access_token: this.token,
      idgrob:this.idZaObrisat
    }
    this.api.obrisiGrob(podaci).subscribe(res => {
        if(res.success){
          notify(res.message,'success');
          this.obrisiPopupVisible=false;
        }else{
          notify(res.message,'error');
          this.obrisiPopupVisible=false;
        }
        this.dohvatiGroblje();
    })
  }

  odustani(){
    this.obrisiPopupVisible=false;
  }

  uveziDatoteku(e){   
    this.value =  e.target.files;
    this.datoteka = this.value;
    if(this.value){
      this.loadingVisible=true;
      this.datoteka.name = this.someInput.nativeElement.value.split("\\").pop();
      let fileList: FileList = this.value;
      if(fileList.length > 0){
        let file: File = fileList[0];  
        this.datoteka = file;
        let formData:FormData = new FormData();
        formData.append('datoteka', file, file.name);
        formData.append('access_token', this.token);
        this.api.uploadExcelGroblje(formData).subscribe(res => {
            if(res.success === true){
              notify(res.message, 'success');
              this.datoteka = null;
              this.dohvatiGroblje();
              this.loadingVisible=false;
            }else{
              notify(res.message, 'error');
              this.loadingVisible=false;
        }})
      }
    } else {
      notify('Došlo je do pogreške, molimo Vas pokušajte kasnije!', 'error');
    } 
  }

  processFile(imageInput: any) {
    this.slika=[];
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
        this.loadingVisible=true;
        if(this.slika.length==0){
        this.slika.unshift(new ImageSnippet(event.target.result, file));
          let formData:FormData = new FormData();
            formData.append('datoteka',this.slika[0].file);
              this.api.uploadKartu(formData).subscribe(res => {
                notify("Uspješno dodana karta!",'success');
                this.dohvatiKartuGroblja();
                this.loadingVisible = false;
              })
      }else{
        notify("Možete odabrati samo jednu fotografiju!",'error');
        this.loadingVisible = false;
      }
    });
    reader.readAsDataURL(file);
  }

  openImage(){
    window.open(this.slika, '_blank');
  }

}