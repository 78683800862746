import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import notify from 'devextreme/ui/notify';
import validationEngine from 'devextreme/ui/validation_engine';

import * as moment from 'moment';

import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-obavijest',
  templateUrl: './obavijest.component.html',
  styleUrls: ['./obavijest.component.scss']
})
export class ObavijestComponent implements OnInit {

  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  obavijestID:any;
  obavijest:any={};
  aktivna:boolean=true;
  validacijaForma: any;
  validno: boolean=false;
  loadingVisible:boolean=false;
  samoPregled: boolean=false;
  isValid:boolean=true;

  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute
  ) {
    this.validationCallback = this.validationCallback.bind(this);
    if(isNaN(this.route.snapshot.params['id']) || this.route.snapshot.params['id'] === null || this.route.snapshot.params['id'] === undefined ){
      this.obavijestID = null;
    }else{
      this.obavijestID = +this.route.snapshot.params['id'];
    }
    this.token = sessionStorage.getItem('token');
    this.nivoPrava=sessionStorage.getItem('nivoPrava');
    this.idkorisnik=sessionStorage.getItem('idkorisnik');
    this.kor_ime=sessionStorage.getItem('kor_ime');
    this.kor_prezime=sessionStorage.getItem('kor_prezime');
    this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
    if(this.nivoPrava==0){
      this.samoPregled=true;
    }
   }

  ngOnInit(): void {
    if(this.obavijestID!=undefined && this.obavijestID !=null){
      this.loadingVisible=true;
      this.dohvatiObavijest();
    }
  }

  validationCallback(e: any) {
    if(e.value!=undefined && e.value!=null && e.value!=""){
      if(e.value.includes("https://")){
        this.isValid=true;
      }else{
        this.isValid=false;
      }
    }else{
      this.isValid=true;
    }
    return this.isValid;
  }

  onContentReady(e){
    this.validacijaForma = validationEngine.validateGroup("forma");
  }

  dohvatiObavijest(){
    let podaci={
      access_token: this.token,
      idobavijest:this.obavijestID
    }
    this.api.dohvatiJednuObavijest(podaci).subscribe(res => {
       this.obavijest=res.data[0];
       setTimeout(() => { 
        this.loadingVisible=false;
        }, 500)
     })
  }

  spremi(e){
    //PROVJERAVANJE VALIDNOSTI SVAKOG PRAVILA
    let br=0;
    for(let i=0;i<this.validacijaForma.brokenRules.length;i++){
      if(this.validacijaForma.brokenRules[i].isValid==true){
        br++;
      }
    }if(br==this.validacijaForma.brokenRules.length){
      this.validno=true;
    }else{
      this.validno=false;
    }
    if(this.validno===true && this.isValid===true){
    if(this.obavijestID == undefined || this.obavijestID == null  ){ //DODAVANJE NOVE OBAVIJESTI
      let podaci = {
        idkorisnik: this.idkorisnik,
        access_token: this.token,
        datum:moment(e.ob_datum).format('YYYY-MM-DD'),
        tekst:e.ob_tekst,
        web:e.ob_web,
        aktivna:e.ob_aktivna
      }
      if(this.samoPregled===false){
      this.api.dodajObavijest(podaci).subscribe(res => {
       if(res.success === true){
        notify(res.message, 'success');
        this.router.navigate(['obavijesti/']); 
      } else {
        notify(res.message, 'error');
      }
      })
      }else{
        notify("Nemate administratorska prava za dodavanje novih obavijesti!", 'error');
      }
    }else{                   //UREĐIVANJE OBAVIJESTI
      let podaci = {
        access_token: this.token,
        idobavijest:this.obavijestID,
        datum:moment(e.ob_datum).format('YYYY-MM-DD'),
        tekst:e.ob_tekst,
        web:e.ob_web,
        aktivna:e.ob_aktivna
      }
      if(this.samoPregled===false){
      this.api.urediObavijest(podaci).subscribe(res => {
          if(res.success === true){
            notify(res.message, 'success');
            this.router.navigate(['obavijesti/']);
          } else {
            notify(res.message, 'error');
          }
      })
    }else{
      notify("Nemate administratorska prava za pohranjivanje postojećih obavijesti!", 'error');
    }
    }
}else{
  notify("Neka polja nisu pravilno popunjena!",'error');
}
}

  odustani(){
    this.obavijest={};
    this.router.navigate(['obavijesti/']);
  }

}