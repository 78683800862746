import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/shared/services/api.service';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-vrste-problema',
  templateUrl: './vrste-problema.component.html',
  styleUrls: ['./vrste-problema.component.scss']
})
export class VrsteProblemaComponent implements OnInit {

  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  row:any;
  loadingVisible = true;
  vrste_problema: any;
  obrisiPopupVisible: boolean=false;
  idZaObrisat: any;
  zaduzeni: any;
  zaduzeniZaPrikaz: any;

  constructor(
    private router: Router,
    private api: ApiService
  ) {
      this.token = sessionStorage.getItem('token');
      this.nivoPrava=sessionStorage.getItem('nivoPrava');
      this.idkorisnik=sessionStorage.getItem('idkorisnik');
      this.kor_ime=sessionStorage.getItem('kor_ime');
      this.kor_prezime=sessionStorage.getItem('kor_prezime');
      this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
  }

  ngOnInit(): void {
    this.dohvatiVrsteProblema();
  }

  onToolbarPreparing(e: any) { 
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'naslovTablice'   
      },
      {
        location: 'after',
        template: "dodaj",
      },
    )
  }

  onFocusedRowChanged(e) {
    this.row=e.row;
  }

  onInitNewRow(e){
    this.router.navigate(['vrste-problema/dodaj-problem/']);  
  }

  dohvatiVrsteProblema(){
    let podaci = {
      access_token: this.token,
    }
    this.api.dohvatiVrsteProblema(podaci).subscribe(res => {
      this.loadingVisible = false;
      this.vrste_problema = res.data;   
    })
  }

  uredi(e){
    this.router.navigate(['vrste-problema/uredi-problem/' + e.data.idproblem]);  
  }

  brisanje(e){
    this.idZaObrisat=e.data.idproblem
    const polje= [];
    for(let i=0;i<e.data.zaduzeni.length;i++){
      polje.push(e.data.zaduzeni[i].kor_ime + " " + e.data.zaduzeni[i].kor_prezime);
    }
    this.zaduzeniZaPrikaz=polje
    this.obrisiPopupVisible=true;
  }

  obrisi(){
    let podaci = {
      access_token: this.token,
      idproblem:this.idZaObrisat
    }
    this.api.obrisiVrstuProblema(podaci).subscribe(res => {
        if(res.success){
          notify(res.message,'success');
          this.obrisiPopupVisible=false;
        }else{
          notify(res.message,'error');
          this.obrisiPopupVisible=false;
        }
        this.dohvatiVrsteProblema()
      })
  }

  odustani(){
    this.obrisiPopupVisible=false;
  }

  zaduzeni_calculateCellValue (rowData) {
    const poljeZaduzenih= [];
    for(let i=0;i<rowData.zaduzeni.length;i++){
        poljeZaduzenih.push(rowData.zaduzeni[i].kor_ime + " " + rowData.zaduzeni[i].kor_prezime);
    }
    return poljeZaduzenih;
  }

}
