import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-odjeli',
  templateUrl: './odjeli.component.html',
  styleUrls: ['./odjeli.component.scss']
})
export class OdjeliComponent implements OnInit {

  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  row:any;
  loadingVisible = true;
  odjeli: any;
  naslov: string;
  isEdited: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private api: ApiService
    ) {
      this.token = sessionStorage.getItem('token');
      this.nivoPrava=sessionStorage.getItem('nivoPrava');
      this.idkorisnik=sessionStorage.getItem('idkorisnik');
      this.kor_ime=sessionStorage.getItem('kor_ime');
      this.kor_prezime=sessionStorage.getItem('kor_prezime');
      this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
      this.onShowing = this.onShowing.bind(this);
    }

  ngOnInit(): void {
    this.dohvatiOdjele();
  }

  onToolbarPreparing(e: any) { 
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'naslovTablice'   
      }
    )
  }

  onShowing(e: any){
    e.component.option("title", this.naslov);
  }

  onFocusedRowChanged(e) {
    this.row=e.row;
  }

  dohvatiOdjele(){
    let podaci = {
      access_token: this.token,
    }
    this.api.dohvatiOdjele(podaci).subscribe(res => {
      this.loadingVisible = false;
      this.odjeli = res.data;   
    })
  }

  onInitNewRow(e){   
    this.naslov = 'Dodaj odjel';
    this.isEdited = false;

  }

  jedanOdjel(e){  
    this.naslov = 'Uredi odjel';
    this.isEdited = true;
  }

  urediOdjel(e){
    let podaci = { 
      idodjel:e.data.idodjel,
      access_token: this.token,
      oznaka: e.data.od_oznaka,
      naziv: e.data.od_naziv,	
    }    
    this.api.urediOdjel(podaci).subscribe(res => { 
      if(res.success === true){
        notify(res.message, 'success');
        this.dohvatiOdjele();
      } else {
        notify(res.message, 'error');
        this.dohvatiOdjele();
      }      
    })
  }

  spremi(e){  
    let podaci = { 
      access_token: this.token,
      oznaka: e.data.od_oznaka,
      naziv: e.data.od_naziv,	
    }
    this.api.dodajOdjel(podaci).subscribe(res => {
      if(res.success === true){
        notify(res.message, 'success');
        this.dohvatiOdjele();
      } else {
        notify(res.message, 'error');
        this.dohvatiOdjele();
      }      
    })
  }

  obrisiOdjel(e){
    let podaci = {
      idkorisnik: this.idkorisnik,
      access_token: this.token,
      idodjel: e.data.idodjel
    }
    this.api.obrisiOdjel(podaci).subscribe(res => {
        if(res.success === true){
          notify(res.message, 'success');
          this.dohvatiOdjele();
        } else {
          notify(res.message, 'error');
          this.dohvatiOdjele();
        }     
    })
  }

}