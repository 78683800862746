import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import notify from 'devextreme/ui/notify';

import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-prijave',
  templateUrl: './prijave.component.html',
  styleUrls: ['./prijave.component.scss']
})
export class PrijaveComponent implements OnInit {

  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  row:any;
  loadingVisible = true;
  prijave:any;
  idZaObrisat: any;
  obrisiPopupVisible: boolean=false;
  slikaZaPrikaz: any;
  popupImageVisible: boolean=false;

  constructor(
    private api: ApiService,
    private router: Router,
  ) {
    this.token = sessionStorage.getItem('token');
    this.nivoPrava=sessionStorage.getItem('nivoPrava');
    this.idkorisnik=sessionStorage.getItem('idkorisnik');
    this.kor_ime=sessionStorage.getItem('kor_ime');
    this.kor_prezime=sessionStorage.getItem('kor_prezime');
    this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
  }

  ngOnInit(): void {
    this.dohvatiPrijave();
  }

  onToolbarPreparing(e: any) { 
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'naslovTablice'   
      },
      {
        location: 'after',
        template: "dodaj",
      },
    )
  }

  onFocusedRowChanged(e) {
    this.row=e.row;
  }
  onInitNewRow(e){
    this.router.navigate(['prijave/dodaj-prijavu/']);  
  }

  dohvatiPrijave(){
    let podaci = {
      access_token: this.token,
      idkorisnik:this.idkorisnik
    }
    this.api.dohvatiPrijave(podaci).subscribe(res => {
      if(res.success==true){
        this.loadingVisible = false;
        this.prijave = res.data;  
      } else{
        this.loadingVisible = false;
        notify("Došlo je do pogreške, pokušajte kasnije!" , 'error');
      }
    })
  }

  uredi(e){ 
      this.router.navigate(['prijave/uredi-prijavu/' + e.data.idprijava]);  
  }

  odustani(){
    this.obrisiPopupVisible=false;
  }

  openImage(e){
    if(e.data.slike.length>0){
      this.slikaZaPrikaz=[]
      for(let i=0;i<e.data.slike.length;i++){
        this.slikaZaPrikaz.push(e.data.slike[i].putanja)
        this.slikaZaPrikaz.join(",")
      }
      this.popupImageVisible=true;  
    }else{
      notify("Odabrana prijava nema fotografija!", 'info');
    }
  }

}