<h2 style="text-align: left ;margin-left: 40px;">Kontakt</h2> 

<div class="content-block dx-card">
    <div class="responsive-paddings" style="margin-left:75px;">
        <dx-load-panel
            #loadPanel
            shadingColor="rgba(0,0,0,0.4)"
            [(visible)]="loadingVisible"
            [showIndicator]="true"
            [showPane]="true"
            [shading]="true"
            [closeOnOutsideClick]="false">
        </dx-load-panel>
        <dx-form
            labelLocation="left"
            [(formData)]="kontakt"
            [showColonAfterLabel]="true"
            validationGroup="forma"
            (onContentReady)="onContentReady($event)"
            [colCount]="2"
            >
            <dxi-item itemType="group">
                <dxi-item dataField="ko_naziv" [label]="{text: 'Naziv'}" editorType="dxTextBox"  [colCount]="2"
                    [editorOptions]="{ stylingMode:'outlined', width:'75%'}">
                        <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                </dxi-item>

                <dxi-item  [label]="{text: 'Adresa'}">
                <input ngx-google-places-autocomplete [options]='options' value="{{ kontakt.ko_adresa }}" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" 
                style="width: 75%;height: 55px; background-color: transparent;border-radius: 4px;
                        font-size: 16px; border: 0.1px solid rgb(0 0 0 / 42%);"/>
                </dxi-item>

                <dxi-item dataField="ko_telefon" [label]="{text: 'Telefon'}" editorType="dxTextBox"  [colCount]="1"
                    [editorOptions]="{ stylingMode:'outlined', width:'75%' }">
                </dxi-item>
                <dxi-item dataField="ko_mobitel" [label]="{text: 'Mobitel'}" editorType="dxTextBox" 
                [editorOptions]="{ stylingMode:'outlined', width:'75%'}">
                </dxi-item>

                <dxi-item dataField="ko_email" [label]="{text: 'Mail'}" editorType="dxTextBox" 
                [editorOptions]="{ stylingMode: 'outlined', mode: 'email', width:'75%'}">
                </dxi-item>
                
                <dxi-item dataField="ko_web" [label]="{text: 'Web'}" editorType="dxTextBox" 
                    [editorOptions]="{ stylingMode:'outlined', width:'200%' }">
                    <dxi-validation-rule type="custom" [validationCallback]="validationCallback" message="Mora sadržavati https://"></dxi-validation-rule>
                </dxi-item>

                <dxi-item>
                    <dx-button 
                        validationGroup="forma"
                        type="success"
                        (onClick)="spremi(kontakt)"
                        >SPREMI
                    </dx-button>

                    <dx-button 
                        validationGroup="forma"
                        type="danger"
                        style="margin-left: 10px ;"
                        (onClick)="odustani()"
                        >ODUSTANI
                    </dx-button>
                </dxi-item>
               
        </dxi-item>
        <dxi-item itemType="group">
            <dxi-item>
                <dx-map
                    [center]="{ lat: 45.116733, lng: 18.537016 }"
                    [controls]="true"
                    [zoom]="15"
                    provider="google"
                    [height]="400"
                    [width]="500"
                    [markers]="mapMarkers"
                    [apiKey]="keys"> 
                </dx-map>
            </dxi-item>
        </dxi-item>     
        
        </dx-form>
    </div>
</div>