import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { Timeouts } from 'selenium-webdriver';

import { ApiService } from 'src/app/shared/services/api.service';


@Component({
  selector: 'app-change-passsword-form',
  templateUrl: './change-password-form.component.html'
})
export class ChangePasswordFormComponent implements OnInit {

  loading = false;
  formData: any = {};
  recoveryCode: string = '';

  constructor(
    public _api: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.recoveryCode = params.get('recoveryCode') || '';
    });
  }

  async onSubmit(e: Event) {

    e.preventDefault();
    const { password } = this.formData;
    this.loading = true;

    this._api.resetSifru({'sifra': this.formData.confirmedPassword, 'token': this.recoveryCode}).subscribe(res=>{
      //console.log(res);
     if(res.success == true){
        this.loading = false;
        notify("Uspješno promijenjena lozinka! Ovaj prozor će se sada zatvoriti", 'success', 4500);
        setTimeout(() => { 
          window.self.close();
          }, 4500)
        
       
     } else {
        notify(res.message, "error", 1000);
     }
   });

  }

  confirmPassword = (e: any) => {
    return e.value === this.formData.password;
  }

}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule
  ],
  declarations: [ ChangePasswordFormComponent ],
  exports: [ ChangePasswordFormComponent ]
})
export class ChangePasswordFormModule { }
