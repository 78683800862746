<form (submit)="onSubmit($event)">
    <dx-form [formData]="formData" [disabled]="loading">
  
      <dxi-item dataField="password" editorType="dxTextBox"
        [editorOptions]="{ stylingMode: 'filled', placeholder: 'Lozinka', mode: 'password' }">
        <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>
  
      <dxi-item dataField="confirmedPassword" editorType="dxTextBox"
        [editorOptions]="{ stylingMode: 'filled', placeholder: 'Ponovi lozinku', mode: 'password' }">
        <dxi-validation-rule type="required" message="Unesite lozinku!"></dxi-validation-rule>
        <dxi-validation-rule type="custom" message="Lozinke nisu jednake1" [validationCallback]="confirmPassword">
        </dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>
  
      <dxi-item itemType="button">
        <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'changePasswordTemplate'">
        </dxo-button-options>
      </dxi-item>
  
      <ng-container *dxTemplate="let item of 'changePasswordTemplate'">
        <div>
          <span class="dx-button-text">
            <ng-container *ngIf="loading; else notLoading">
              <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
            </ng-container>
            <ng-template #notLoading>Resetiraj lozinku</ng-template>
          </span>
        </div>
      </ng-container>
  
    </dx-form>
  </form>
  