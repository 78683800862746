<h2 style="text-align: left ;margin-left: 40px;">Korisnik</h2> 

<div class="content-block dx-card">
    <div class="responsive-paddings" style="margin-left:75px;">
        <dx-load-panel
            #loadPanel
            shadingColor="rgba(0,0,0,0.4)"
            [(visible)]="loadingVisible"
            [showIndicator]="true"
            [showPane]="true"
            [shading]="true"
            [closeOnOutsideClick]="false">
        </dx-load-panel>
        <dx-form
            labelLocation="top"
            [(formData)]="korisnik"
            [showColonAfterLabel]="true"
            validationGroup="forma"
            (onContentReady)="onContentReady($event)"
            >
        <dxi-item itemType="group" [colCount]="2">
            <dxi-item dataField="kor_ime" [label]="{text: 'Ime korisnika'}" [editorOptions]="{ stylingMode:'outlined'}">
                <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
            </dxi-item>   
            <dxi-item dataField="kor_prezime" [label]="{text: 'Prezime korisnika'}" [editorOptions]="{ stylingMode:'outlined'}">
                <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
            </dxi-item> 
            <dxi-item dataField="kor_lozinka" [label]="{text: 'Lozinka'}" [editorOptions]="{ stylingMode:'outlined'}">
                <div class="lozinka">
                <dx-text-box [(value)]="korisnik.kor_lozinka" [(mode)]="passwordMode" stylingMode="outlined" > 
                    <dx-validator validationGroup="forma">
                    <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>  
                    </dx-validator>  
                </dx-text-box>
                <div class="buttoni">
                    <dx-button *ngIf="passwordMode == 'password'" icon="doesnotcontain"  hint="Prikaži lozinku" (onClick)="otkrijLozinku()" ></dx-button>
                    <dx-button *ngIf="passwordMode == 'text'" icon="startswith" (onClick)="otkrijLozinku()" hint="Sakrij lozinku"></dx-button>
                </div>
                </div>
            </dxi-item> 
            <dxi-item dataField="od_naziv" [label]="{text: 'Naziv odjela'}" editorType="dxSelectBox"  [editorOptions]="{ 
                dataSource: odjeli ,
                valueExpr: 'od_naziv',
                displayExpr: displayOdjel,
                onItemClick: odjelClick,
                stylingMode:'outlined',
                searchEnabled:'true'}">
                <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
            </dxi-item>   
        </dxi-item>  
        <dxi-item itemType="group" [colCount]="2">
            <dxi-item dataField="kor_email" [label]="{text: 'Email'}" [editorOptions]="{ stylingMode:'outlined',mode: 'email'}">
                <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
            </dxi-item> 
            <dxi-item dataField="kor_telefon" [label]="{text: 'Telefon'}" [editorOptions]="{ stylingMode:'outlined'}"></dxi-item> 
            <dxi-item dataField="kor_admin" [label]="{text: 'Admin'}" editorType="dxCheckBox" [editorOptions]="{ stylingMode:'outlined'}" cssClass="ikona" ></dxi-item> 
            <dxi-item dataField="kor_aktivan" [label]="{text: 'Aktivan'}" editorType="dxCheckBox" [editorOptions]="{ stylingMode:'outlined',value:'true'}" cssClass="ikona" ></dxi-item> 
        </dxi-item>
                <dxi-item>
                    <dx-button 
                        validationGroup="forma"
                        type="success"
                        (onClick)="spremi(korisnik)"
                        >SPREMI
                    </dx-button>

                    <dx-button 
                        validationGroup="forma"
                        type="danger"
                        style="margin-left: 10px ;"
                        (onClick)="odustani()"
                        >ODUSTANI
                    </dx-button>
                </dxi-item>
        
        </dx-form>
    </div>
</div>












