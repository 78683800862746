<div class="content-block">
    <dx-load-panel
        #loadPanel
        shadingColor="rgba(0,0,0,0.4)"
        [(visible)]="loadingVisible"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [closeOnOutsideClick]="false">
    </dx-load-panel>
    <dx-data-grid 
                #gridcontainer
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                [hoverStateEnabled]="true"
                [wordWrapEnabled]="true"
                [allowColumnReordering]="false"
                [allowColumnResizing]= "true"
                [showBorders]="true" 
                [showRowLines]="true" 
                [focusedRowEnabled]="true"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                [autoNavigateToFocusedRow]="false"
                id="gridcontainerid"
                keyExpr="idgrob"
                [showBorders]="true" 
                class="dx-card wide-card"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                [dataSource]="groblje"
                >

                 <dxo-search-panel [visible]="true" placeholder="Pretraži..." ></dxo-search-panel>
                 <dxo-filter-row [visible]="true"></dxo-filter-row>
                 <dxo-paging [pageSize]="15"></dxo-paging>
                 <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[30, 60, 90]" [showInfo]="true" [visible]="true"></dxo-pager>
                 
                <dxi-column  caption="#" cellTemplate="rb" width="50" [formItem]="{ visible: false }" alignment="center" >
                    <div *dxTemplate="let data of 'rb'">     
                        <div> {{ data.component.pageIndex() * data.component.pageSize() + data.rowIndex+1 }}. </div>
                    </div>
                </dxi-column> 

                <dxi-column dataField="gr_ime" caption="Prezime i ime" alignment="left" dataType="string"></dxi-column>
                <dxi-column dataField="gr_parcela" caption="Parcela" alignment="center" dataType="string" [allowFiltering]="false"></dxi-column> 
                <dxi-column dataField="gr_polje" caption="Polje" alignment="center" dataType="string" [allowFiltering]="false"></dxi-column> 
                <dxi-column dataField="gr_red" caption="Red" alignment="center" [allowFiltering]="false"></dxi-column>
                <dxi-column dataField="gr_brojgroba" caption="Broj groba" alignment="center" dataType="string"></dxi-column>
                <dxi-column dataField="gr_nazivgroblja" caption="Naziv groblja" alignment="left" dataType="string"></dxi-column> 
                <dxi-column dataField="gr_vrstagrmjesta" caption="Vrsta grobnog mjesta" alignment="left" dataType="string"></dxi-column> 
                <dxi-column dataField="gr_datumukopa" caption="Datum ukopa" alignment="center" dataType="date" [allowFiltering]="false"></dxi-column> 

                <dxi-column  type="buttons" alignment="center" ><dxi-button template="buttonTemplate" ></dxi-button></dxi-column>
                <div *dxTemplate="let buttonInfo of 'buttonTemplate'" class="ikona"> 
                    <dx-button icon="edit" id="btn"  (click)="uredi(buttonInfo)"></dx-button>
                    <dx-button icon="trash" id="btn"  (click)="brisanje(buttonInfo)"></dx-button>
                </div>  

                <div *dxTemplate="let data of 'dodaj'">
                    
                    <label class="btn-bwm">
                        <span ><i class="dx-icon-xlsfile dx-icon-custom-style"> </i> </span>
                            <input style="display: none" type="file" (change)="uveziDatoteku($event)" accept=".xlsx" #inputData id="inputData">
                                <button (click)="inputData.click()" style="display:none;"></button>  
                                UVEZI IZ EXCEL-a     
                    </label>

                    <label  class="btn-bwm" style="background-color: #00b4d8;">
                        <span ><i class="dx-icon-image dx-icon-custom-style"> </i> </span>
                        <input #imageInput 
                               style="display: none;"
                               type="file"
                               accept="image/*"
                               (change)="processFile(imageInput)">
                               UVEZI KARTU GROBLJA
                    </label>

                    <dx-button icon="image" text="KARTA GROBLJA" style="background-color:#00b4d8; color:black;"  (onClick)="openImage()"></dx-button>
                    <dx-button icon="add" hint="Dodavanje grobnog mjesta" (onClick)="onInitNewRow($event)"></dx-button>
                </div>
                <div *dxTemplate="let data of 'naslovTablice'">
                    <div class="naslov">
                        <p class="name">Grobna mjesta</p>
                    </div>
                </div>
                
    </dx-data-grid>

   
</div>    

<!-------------------------- Popup za potvrdu brisanja  ---------------->

<dx-popup
[closeOnOutsideClick]="false"
[showCloseButton]="true"
[(visible)]="obrisiPopupVisible"
[width]="400"
[height]="250"
title="Brisanje obavijesti"
>
<p style="font-size: 16px; font-weight: bold;">Jeste li sigurni da želite ukloniti odabrano grobno mjesto?</p>
<br>
<dx-button text="ODUSTANI" style="float:right;" (onClick)="odustani()"></dx-button>
<dx-button text="OBRIŠI" style="background-color:red; color:white; float:right;"  (onClick)="obrisi()"></dx-button>
</dx-popup>


<dx-popup
[closeOnOutsideClick]="false"
[showCloseButton]="true"
[(visible)]="popupImageVisible"
[height]="900"
[width]="1000"
title="Karta Groblja"
>
    <dx-gallery
      #gallery
      id="gallery"
      [dataSource]="slikaZaPrikaz"
      [height]="800"
    ></dx-gallery>
</dx-popup>