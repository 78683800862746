import { Injectable } from '@angular/core';

@Injectable()
 
export class AppConfig {

  //LAB - TEST
  //public static ipAddress = "https://lab.fortuno.hr:3041/api/";
 
  // PRODUKCIJA
   public static ipAddress = "https://prijava.babinagreda.hr:3005/api/";

}