
<form *ngIf="loginVisible==true" class="login-form" (submit)="onSubmit($event)">

  <div class="login-header">
    <img class="logo" src="assets/logo.jpg" />
  </div>

  <dx-form [formData]="formData" [disabled]="loading" (onEditorEnterKey)="onEditorEnterKey($event)">
    <dxi-item dataField="username" editorType="dxTextBox" #inputName 
    [editorOptions]="{ stylingMode: 'filled', mode: 'username' }"> 
      <!-- <dxi-validation-rule type="required" message="Korisničko ime je obavezno polje"></dxi-validation-rule> -->
      <dxo-label [visible]="true" text="Korisničko ime:"></dxo-label>
    </dxi-item>
    <dxi-item dataField="password" editorType="dxTextBox">
      <div *dxTemplate class="lozinka">
      <dx-text-box [(value)]="password" [(mode)]="passwordMode" stylingMode="filled" ></dx-text-box>
      <div class="buttoni">
        <dx-button *ngIf="passwordMode == 'password'" icon="doesnotcontain" (onClick)="otkrijLozinku()" hint="Prikaži lozinku"></dx-button>
        <dx-button *ngIf="passwordMode == 'text'" icon="startswith" (onClick)="otkrijLozinku()" hint="Sakrij lozinku"></dx-button>
      </div>
      </div>
      <dxi-validation-rule type="required" ></dxi-validation-rule>
      <dxo-label [visible]="true" text="Lozinka:"></dxo-label>
      </dxi-item>
    <dxi-item itemType="button">
      <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'signInTemplate'">
      </dxo-button-options>
    </dxi-item>
    <ng-container *dxTemplate="let item of 'signInTemplate'">
      <span class="dx-button-text">
        <ng-container *ngIf="loading; else notLoading">
          <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
        </ng-container>
        <ng-template #notLoading>Prijava</ng-template>
      </span>
    </ng-container>
  </dx-form>
  <a  style="cursor:pointer" class="zaboravljenalozinka" id="zaboravljenalozinka" (click)="test()" >Zaboravljena lozinka?</a>
  <!-- <span style="float: right;">03.08.2022.</span> -->
  
</form>

<form *ngIf="sendEmailVisible==true" class="login-form" (submit)="onSubmitEmail($event)">

  <div class="login-header">
    <img class="logo" src="assets/logo.jpg" />
  </div>

  <dx-form [formData]="formDataEmail" [disabled]="loading" (onEditorEnterKey)="onEditorEnterKey($event)">
    <dxi-item dataField="email" editorType="dxTextBox" #inputEmail
    [editorOptions]="{ stylingMode: 'filled', mode: 'email' }"> 
      <dxo-label [visible]="true" text="Email"></dxo-label>
      <dxi-validation-rule type="required" ></dxi-validation-rule>
    </dxi-item>
    <dxi-item itemType="button">
      <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'signInTemplate'">
      </dxo-button-options>
    </dxi-item>
    <ng-container *dxTemplate="let item of 'signInTemplate'">
      <span class="dx-button-text">
        <ng-container *ngIf="loading; else notLoading">
          <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
        </ng-container>
        <ng-template #notLoading>Pošalji zahtjev</ng-template>
      </span>
    </ng-container>
    <dxi-item>
      <dx-button class="dx-button-text" (onClick)="natrag()">Natrag</dx-button>
    </dxi-item>
  </dx-form> 
</form>

