import { CommonModule } from '@angular/common';
import { Component, NgModule, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { DxTextBoxComponent, DxTextBoxModule } from 'devextreme-angular';

import { AuthService } from '../../services';
import { ApiService } from 'src/app/shared/services/api.service';

const notificationText = 'Poslali smo poveznicu za poništavanje vaše lozinke. Provjerite svoj inbox.';

@Component({
  selector: 'app-login',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements AfterViewInit, OnInit {

  @ViewChild("inputName", { static: false }) inputName: DxTextBoxComponent;

  loading = false;
  loginVisible:boolean=true;
  formData: any = {};
  passwordMode: string = "password";
  password: string;
  formDataReset: any = {};
  recoveryCode: string = '';
  sendEmailVisible:boolean=false;
  formDataEmail: any = {};

  constructor(
    private authService: AuthService, 
    private router: Router,
    public _api: ApiService,
    private route: ActivatedRoute
  ) {}

  async onSubmit(e) {
    e.preventDefault();
    
    this.loading = true;

    this._api.login({'email': this.formData.username, 'sifra': this.password}).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        //console.log(res)
        if(res.data[0].gost_idgost==undefined || res.data[0].gost_idgost==null){
          sessionStorage.setItem('idgost', "0");
        }else{
          sessionStorage.setItem('idgost', res.data[0].gost_idgost);
        }
        sessionStorage.setItem('nivoPrava', res.data[0].kor_admin);
        sessionStorage.setItem('idkorisnik', res.data[0].idkorisnik);
        sessionStorage.setItem('kor_ime', res.data[0].kor_ime);
        sessionStorage.setItem('kor_prezime', res.data[0].kor_prezime);
        sessionStorage.setItem('odjel_idodjel', res.data[0].odjel_idodjel);
        sessionStorage.setItem('token', res.token.token);
        sessionStorage.setItem('username', res.token.username);
        this.authService.logIn(this.formData.username, this.password, res.data[0].kor_admin); // 0 - admin, 1 - Korisnik
        this.loading = false;
      } else {
        this.loading = false;
        notify("Korisnik ne postoji ili nije aktivan!", "error");
      }
    });

  }
  async onSubmitEmail(e: Event) {
    e.preventDefault();
    //console.log({'email': this.formDataEmail.email});

    this._api.zatraziSifru({'email': this.formDataEmail.email}).subscribe(res=>{
      //console.log(res);
     if(res.success == true){
        this.recoveryCode=res.data[0].token
        this.sendEmailVisible=false;
        this.loginVisible=true;
        notify(notificationText, 'success', 2500);
     } else {
      notify("Unesena e-mail adresa ne postoji!", "error", 1000);
     }
   });

  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.inputName.instance.focus();
  }

  onEditorEnterKey(e){
    e.component.getEditor('email').focus();
  }

  otkrijLozinku(){
    this.passwordMode = this.passwordMode === "text" ? "password" : "text";
  }

  test(){
    this.sendEmailVisible=true;
    this.loginVisible=false;
  }

  natrag(){
    this.sendEmailVisible=false;
    this.loginVisible=true;
    this.formDataEmail={};
  }
  
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxButtonModule,
    DxTextBoxModule
  ],
  declarations: [ LoginFormComponent ],
  exports: [ LoginFormComponent ]
})
export class LoginFormModule { }