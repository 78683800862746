<div class="content-block">
     <dx-load-panel
        #loadPanel
        shadingColor="rgba(0,0,0,0.4)"
        [(visible)]="loadingVisible"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [closeOnOutsideClick]="false">
    </dx-load-panel> 
    <dx-data-grid 
                #gridcontainer
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                [hoverStateEnabled]="true"
                [wordWrapEnabled]="true"
                [allowColumnReordering]="false"
                [allowColumnResizing]= "true"
                [showBorders]="true" 
                [showRowLines]="true" 
                [focusedRowEnabled]="true"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                [autoNavigateToFocusedRow]="false"
                id="gridcontainerid"
                keyExpr="idprijava"
                [showBorders]="true" 
                class="dx-card wide-card"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                [dataSource]="prijave"
                 >

                 <dxo-search-panel [visible]="true" placeholder="Pretraži..." ></dxo-search-panel>
                 <dxo-paging [pageSize]="15"></dxo-paging>
                 <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[30, 60, 90]" [showInfo]="true" [visible]="true"></dxo-pager>
                 
                <dxi-column dataField="pr_naziv" caption="Naslov" alignment="left" dataType="string"></dxi-column>
                <dxi-column dataField="pr_opis" caption="Opis" alignment="left" dataType="string"></dxi-column>
                <dxi-column dataField="pr_adresa" caption="Lokacija" alignment="left" dataType="string"></dxi-column> 
                <dxi-column dataField="vrpr_naziv" caption="Vrsta problema" alignment="left" dataType="string"></dxi-column> 
                <dxi-column dataField="pr_datum" caption="Datum prijave" alignment="center"  dataType="date" width="150px"></dxi-column>
                <dxi-column caption="Fotografija" type="buttons" name="photo" alignment="center" ><dxi-button template="fotografije" ></dxi-button></dxi-column>
                <div *dxTemplate="let buttonInfo of 'fotografije'" class="ikona"> 
                    <dx-button icon="image" id="btn" hint="Prikaži fotografije"  (click)="openImage(buttonInfo)"></dx-button>
                </div>  

                <dxi-column dataField="pr_status" caption="Status" alignment="center" cellTemplate="status" width="100" > </dxi-column>

                <dxi-column  type="buttons" alignment="center" ><dxi-button template="buttonTemplate" ></dxi-button></dxi-column>
                <div *dxTemplate="let buttonInfo of 'buttonTemplate'" class="ikona"> 
                    <dx-button icon="edit" id="btn"  (click)="uredi(buttonInfo)"></dx-button>
                </div>  

                <div *dxTemplate="let data of 'slika'">
                    <dx-button icon="image" hint="Pregled slike" (onClick)="onInitNewRow($event)"></dx-button>
                </div>

                <div *dxTemplate="let data of 'dodaj'">
                    <dx-button icon="add" hint="Dodavanje prijave" (onClick)="onInitNewRow($event)"></dx-button>
                </div>

                <div *dxTemplate="let data of 'status'">
                    <div *ngIf="data.data.pr_status==1">
                        Zaprimljeno
                    </div>
                    <div *ngIf="data.data.pr_status==2">
                        U postupku
                    </div>
                    <div *ngIf="data.data.pr_status==3">
                        Riješeno
                    </div>
                </div>

                <div *dxTemplate="let data of 'naslovTablice'">
                    <div class="naslov">
                        <p class="name">Prijave</p>
                    </div>
                </div>

    </dx-data-grid>
</div>    

<!-------------------------- Popup za potvrdu brisanja  ----------------------------->

<!-- <dx-popup
[closeOnOutsideClick]="false"
[showCloseButton]="true"
[(visible)]="obrisiPopupVisible"
[width]="400"
[height]="250"
title="Brisanje prijave"
>
<p style="font-size: 16px; font-weight: bold;">Jeste li sigurni da želite ukloniti odabranu prijavu?</p>
<br>
<dx-button text="ODUSTANI" style="float:right;" (onClick)="odustani()"></dx-button>
<dx-button text="OBRIŠI" style="background-color:red; color:white; float:right;"  (onClick)="obrisi()"></dx-button>

</dx-popup> -->
<!-------------------------- Popup za sliku  ---------------------------->
<dx-popup
[closeOnOutsideClick]="false"
[showCloseButton]="true"
[(visible)]="popupImageVisible"
[height]="900"
[width]="1000"
title="Fotografija prijave"
>
    <dx-gallery
      #gallery
      id="gallery"
      [dataSource]="slikaZaPrikaz"
      [height]="800"

    ></dx-gallery>
</dx-popup>