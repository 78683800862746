import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/shared/services/api.service';

import { DxAutocompleteComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import validationEngine from 'devextreme/ui/validation_engine';

import * as moment from 'moment';
import { stringify } from 'querystring';


@Component({
  selector: 'app-problem',
  templateUrl: './problem.component.html',
  styleUrls: ['./problem.component.scss']
})
export class ProblemComponent implements OnInit {

  @ViewChild('autocompleteZaduzeni') autocompleteZaduzeni!: DxAutocompleteComponent;
  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  problemID:any;
  problem:any={};
  validacijaForma: any;
  validno: boolean=false;
  zaduzeni: any[]=[];
  row: any;
  popupAutocompleteVisible: boolean=false;
  korisnici: any;
  IdSvihKorisnika:any[]=[];
  loadingVisible: boolean=false;

  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute
  ) {
    if(isNaN(this.route.snapshot.params['id']) || this.route.snapshot.params['id'] === null || this.route.snapshot.params['id'] === undefined ){
      this.problemID = null;
    }else{
      this.problemID = +this.route.snapshot.params['id'];
    }
    this.token = sessionStorage.getItem('token');
    this.nivoPrava=sessionStorage.getItem('nivoPrava');
    this.idkorisnik=sessionStorage.getItem('idkorisnik');
    this.kor_ime=sessionStorage.getItem('kor_ime');
    this.kor_prezime=sessionStorage.getItem('kor_prezime');
    this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
   }

  ngOnInit(): void {
    if(this.problemID!=undefined && this.problemID !=null ){
      this.dohvatiProblem();
      this.loadingVisible=true;
    }
    this.dohvatiKorisnike();
  }

  onContentReady(e){
    this.validacijaForma = validationEngine.validateGroup("forma");
  }

  onToolbarPreparing(e: any) { 
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'naslovTablice'   
      },
      {
        location: 'after',
        template: "dodaj",
      },
    )
  }

  onFocusedRowChanged(e) {
    this.row=e.row;
  }

  onInitNewRow(e){
    this.popupAutocompleteVisible=true;
  }

  dohvatiProblem(){
  let podaci={
    idproblem:this.problemID,
    access_token:this.token
  }
    this.api.dohvatiJednuVrstuProblema(podaci).subscribe(res => {
      this.problem=res.data[0];
      for(let i=0;i<res.data[0].zaduzeni.length;i++){
      this.zaduzeni.push(res.data[0].zaduzeni[i]);
      this.IdSvihKorisnika.push(res.data[0].zaduzeni[i].idkorisnik)
    }
    setTimeout(() => { 
      this.loadingVisible=false;
      }, 500)
  })
}
  dohvatiKorisnike(){
    let podaci={
      access_token:this.token
    }
    this.api.dohvatiKorisnike(podaci).subscribe(res => {
      this.korisnici = res.data;
    })
  }

  spremi(e){
    //PROVJERAVANJE VALIDNOSTI SVAKOG PRAVILA
    let br=0;
    for(let i=0;i<this.validacijaForma.brokenRules.length;i++){
      if(this.validacijaForma.brokenRules[i].isValid==true){
        br++;
      }
    }if(br==this.validacijaForma.brokenRules.length){
      this.validno=true;
    }else{
      this.validno=false;
    }
    if(this.validno===true){
    if(this.problemID == undefined || this.problemID == null  ){ //DODAVANJE NOVOG PROBLEMA
      let podaci = {
        access_token: this.token,
        naziv:e.pr_naziv,
        korisnici:this.IdSvihKorisnika,
      }
      this.api.dodajVrstuProblema(podaci).subscribe(res => { 
       if(res.success === true){
        notify(res.message, 'success');
        this.router.navigate(['vrste-problema/']); 
      } else {
        notify(res.message, 'error');
      }
      })
    }else{                   //UREĐIVANJE PROBLEMA
      let podaci = {
        access_token: this.token,
        idproblem:this.problemID,
        naziv:e.pr_naziv,
        korisnici:this.IdSvihKorisnika,
      }
      this.api.urediVrstuProblema(podaci).subscribe(res => { 
       if(res.success === true){
        notify(res.message, 'success');
        this.router.navigate(['vrste-problema/']); 
      } else {
        notify(res.message, 'error');
      }
      })
    }
}else{
  notify("Neka polja nisu pravilno popunjena!",'error');
}
}

  odustani(){
  this.problem={};
  this.router.navigate(['vrste-problema/']);
  }

  zaduzeniClick(e){
    //console.log(e)
    let imeIprezime = 
      {
        kor_ime: String,
        kor_prezime: String
      }
    imeIprezime.kor_ime= e.itemData.kor_ime.split(" ",1).toString();   
    imeIprezime.kor_prezime= e.itemData.kor_ime.split(" ",2).splice(1).toString();
    let x = this.zaduzeni.filter(item => item.kor_ime == imeIprezime.kor_ime && item.kor_prezime == imeIprezime.kor_prezime)
      if(x.length==0){
        this.zaduzeni.push(imeIprezime);
        this.IdSvihKorisnika.push(e.itemData.idkorisnik);
      }else{
        notify("Taj korisnik je već odabran!",'error');
      }
  }

  brisanje(e){
    this.zaduzeni = this.zaduzeni.filter(item => item.kor_ime != e.data.kor_ime && item.kor_prezime != e.data.kor_prezime);
    this.IdSvihKorisnika = this.IdSvihKorisnika.filter(item => item != e.data.idkorisnik);
  }

  zatvoriPopup(){
    this.popupAutocompleteVisible=false;
  }

}
