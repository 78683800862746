import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private _http: HttpClient
  ) { }

  login(a: any){
    return this._http.post(AppConfig.ipAddress + 'login', a)
      .pipe(map((res: any) => res ));
  }
  resetSifru(a: any){
    return this._http.post(AppConfig.ipAddress + 'resetSifru', a)
      .pipe(map((res: any) => res ));
  }
  zatraziSifru(a: any){
    return this._http.post(AppConfig.ipAddress + 'zatraziSifru', a)
      .pipe(map((res: any) => res ));
  }
  //GROBLJE
  uploadExcelGroblje(a: any){
    return this._http.post(AppConfig.ipAddress + 'uploadExcel', a)
      .pipe(map((res: any) => res ));
  }
  dodajGrob(a: any){
    return this._http.post(AppConfig.ipAddress + 'dodajGrob', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiGroblje(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiGroblje', a)
      .pipe(map((res: any) => res ));
  }
  urediGrob(a: any){
    return this._http.post(AppConfig.ipAddress + 'urediGrob', a)
      .pipe(map((res: any) => res ));
  }
  obrisiGrob(a: any){
    return this._http.post(AppConfig.ipAddress + 'obrisiGrob', a)
      .pipe(map((res: any) => res ));
  }
  uploadKartu(a: any){
    return this._http.post(AppConfig.ipAddress + 'uploadKartu', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiKartu(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiKartu', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiJedanGrob(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiJedanGrob', a)
      .pipe(map((res: any) => res ));
  }
  //KONTAKTI
  dodajKontakt(a: any){
    return this._http.post(AppConfig.ipAddress + 'dodajKontakt', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiKontakte(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiKontakte', a)
      .pipe(map((res: any) => res ));
  }
  urediKontakt(a: any){
    return this._http.post(AppConfig.ipAddress + 'urediKontakt', a)
      .pipe(map((res: any) => res ));
  }
  obrisiKontakt(a: any){
    return this._http.post(AppConfig.ipAddress + 'obrisiKontakt', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiJedanKontakt(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiJedanKontakt', a)
      .pipe(map((res: any) => res ));
  }
  //KORISNICI
  dodajKorisnika(a: any){
    return this._http.post(AppConfig.ipAddress + 'dodajKorisnika', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiKorisnike(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiKorisnike', a)
      .pipe(map((res: any) => res ));
  }
  urediKorisnika(a: any){
    return this._http.post(AppConfig.ipAddress + 'urediKorisnika', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiJednogKorisnika(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiJednogKorisnika', a)
      .pipe(map((res: any) => res ));
  }
  //OBAVIJESTI
  dodajObavijest(a: any){
    return this._http.post(AppConfig.ipAddress + 'dodajObavijest', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiObavijesti(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiObavijesti', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiJednuObavijest(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiJednuObavijest', a)
      .pipe(map((res: any) => res ));
  }
  urediObavijest(a: any){
    return this._http.post(AppConfig.ipAddress + 'urediObavijest', a)
      .pipe(map((res: any) => res ));
  }
  obrisiObavijest(a: any){
    return this._http.post(AppConfig.ipAddress + 'obrisiObavijest', a)
      .pipe(map((res: any) => res ));
  }
  //ODJELI
  dodajOdjel(a: any){
    return this._http.post(AppConfig.ipAddress + 'dodajOdjel', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiOdjele(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiOdjele', a)
      .pipe(map((res: any) => res ));
  }
  obrisiOdjel(a: any){
    return this._http.post(AppConfig.ipAddress + 'obrisiOdjel', a)
      .pipe(map((res: any) => res ));
  }
  urediOdjel(a: any){
    return this._http.post(AppConfig.ipAddress + 'urediOdjel', a)
      .pipe(map((res: any) => res ));
  }
  //VRSTE PROBLEMA
  dodajVrstuProblema(a: any){
    return this._http.post(AppConfig.ipAddress + 'dodajVrstuProblema', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiVrsteProblema(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiVrsteProblema', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiJednuVrstuProblema(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiJednuVrstuProblema', a)
      .pipe(map((res: any) => res ));
  }
  obrisiVrstuProblema(a: any){
    return this._http.post(AppConfig.ipAddress + 'obrisiVrstuProblema', a)
      .pipe(map((res: any) => res ));
  }
  urediVrstuProblema(a: any){
    return this._http.post(AppConfig.ipAddress + 'urediVrstuProblema', a)
      .pipe(map((res: any) => res ));
  }
  //DOGAĐAJI
  obrisiDogadaj(a: any){
    return this._http.post(AppConfig.ipAddress + 'obrisiDogadjaj', a)
      .pipe(map((res: any) => res ));
  }
  dodajDogadaj(a: any){
    return this._http.post(AppConfig.ipAddress + 'dodajDogadjaj', a)
      .pipe(map((res: any) => res ));
  }
  urediDogadaj(a: any){
    return this._http.post(AppConfig.ipAddress + 'urediDogadjaj', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiDogadaje(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiDogadjaje', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiJedanDogadaj(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiJedanDogadjaj', a)
      .pipe(map((res: any) => res ));
  }
  //Prijave
  dodajPrijavu(a: any){
    return this._http.post(AppConfig.ipAddress + 'dodajPrijavu', a)
      .pipe(map((res: any) => res ));
  }
  urediPrijavu(a: any){
    return this._http.post(AppConfig.ipAddress + 'urediPrijavu', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiPrijave(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiPrijave', a)
      .pipe(map((res: any) => res ));
  }
  dohvatiJednuPrijavu(a: any){
    return this._http.post(AppConfig.ipAddress + 'dohvatiJednuPrijavu', a)
      .pipe(map((res: any) => res ));
  }
}