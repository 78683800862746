import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

import notify from 'devextreme/ui/notify';

const defaultPath = '/';


@Injectable()
export class AuthService {

  prava: number;
  token: string;
  private _loggedIn: boolean = false;
  get loggedIn(): boolean {
    return this._loggedIn;
  }

  private _lastAuthenticatedPath: string = defaultPath;
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  constructor(
    private router: Router
  ) { this.imaToken(); }

  logIn(username: string, password: string, nivoPrava: any) {

    this.prava = nivoPrava;
    //this._loggedIn = true;
    //this.router.navigate(['/pocetna']);

    // console.log(nivoPrava);

    if(this.prava == 0 || this.prava == 1){
      this._loggedIn = true;
      this.router.navigate(['/pocetna']);
    } else {
      this._loggedIn = false;
      notify("Korisnik ne postoji, nije aktivan ili nema pripadajuća prava!", "error");
      this.router.navigate(['/login']);
    }
    
    // this.router.navigate([this._lastAuthenticatedPath]);
  }

  async getUser() {
     try {
       // Send request

       return {
         isOk: true,
         data: {
          email: sessionStorage.getItem('username')
          // avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/01.png'
          }  
       };
     }
     catch {
       return {
         isOk: false
       };
     }
  }

  async logOut() {
    this._loggedIn = false;
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  imaToken(){
    this.token = sessionStorage.getItem('token');
      if(this.token != null || this.token != undefined){
         this._loggedIn = true;
      } else {
         this._loggedIn = false;
      }
  }

}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.loggedIn;
    const isAuthForm = [
      'login'
    ].includes(route.routeConfig.path);

    if (isLoggedIn && isAuthForm) {
      this.authService.lastAuthenticatedPath = defaultPath;
      this.router.navigate([defaultPath]);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(['/login']);
    }

    if (isLoggedIn) {
      this.authService.lastAuthenticatedPath = route.routeConfig.path;
    }

    return isLoggedIn || isAuthForm;
  }
}
