import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import validationEngine from 'devextreme/ui/validation_engine';
import notify from 'devextreme/ui/notify';

import * as moment from 'moment';

import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';


class ImageSnippet{
  pending: boolean;
  status: string;
  constructor(public src:string, public file: File){}
}

@Component({
  selector: 'app-dogadaj',
  templateUrl: './dogadaj.component.html',
  styleUrls: ['./dogadaj.component.scss']
})
export class DogadajComponent implements OnInit {

  @ViewChild('inputData') someInput: ElementRef;
  token:any;
  idkorisnik:any;
  kor_ime:any;
  kor_prezime:any;
  odjel_idodjel:any;
  nivoPrava:any;
  idpartner:any;
  window: any;
  dogadajID:any;
  dogadaj:any={};
  aktivna:boolean=true;
  validacijaForma: any;
  validno: boolean=false;
  selectedFile: ImageSnippet;
  popupImageVisible: boolean=false;
  slikeZaPrikaz: string[];
  slike: any=[];
  triSlike:any=[];
  slikeFiles: any=[];
  obslike: any[]=[];
  noveSlike:any=[];
  loadingVisible: boolean=false;
  aktivnoZaSlanje:any;
  samoPregled:boolean=false;
  isValid: boolean=true;

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.validationCallback = this.validationCallback.bind(this);
    if(isNaN(this.route.snapshot.params['id']) || this.route.snapshot.params['id'] === null || this.route.snapshot.params['id'] === undefined ){
      this.dogadajID = null;
    }else{
      this.dogadajID = +this.route.snapshot.params['id'];
    }
    this.token = sessionStorage.getItem('token');
    this.nivoPrava=sessionStorage.getItem('nivoPrava');
    this.idkorisnik=sessionStorage.getItem('idkorisnik');
    this.kor_ime=sessionStorage.getItem('kor_ime');
    this.kor_prezime=sessionStorage.getItem('kor_prezime');
    this.odjel_idodjel=sessionStorage.getItem('odjel_idodjel');
    this.idpartner=sessionStorage.getItem('idpartner');
    if(this.nivoPrava==0){
      this.samoPregled=true;
    }
   }

  ngOnInit(): void {
    if(this.dogadajID!=undefined && this.dogadajID !=null ){
      this.loadingVisible=true;
      this.dohvatiDogadaj();
    }
  }

  onContentReady(e){
    //console.log(e);
    this.validacijaForma = validationEngine.validateGroup("forma");
  }

  validationCallback(e: any) {
    if(e.value!=undefined && e.value!=null && e.value!=""){
      if(e.value.includes("https://")){
        this.isValid=true;
      }else{
        this.isValid=false;
      }
    }else{
      this.isValid=true;
    }
    return this.isValid;
  }

  dohvatiDogadaj(){
    let podaci={
      access_token: this.token,
      iddogadjaj:this.dogadajID
    }
    this.api.dohvatiJedanDogadaj(podaci).subscribe(res => {
        this.dogadaj=res.data[0];
        for(let i=0;i<res.data[0].slike.length;i++){
          this.triSlike[i]=res.data[0].slike[i].putanja
          this.slikeFiles=res.data[0].slike
        }
        setTimeout(() => { 
          this.loadingVisible=false;
        }, 500)
    })
  }

  spremi(e){
    //PROVJERAVANJE VALIDNOSTI SVAKOG PRAVILA
    let formData:FormData = new FormData();
    let br=0;
    for(let i=0;i<this.validacijaForma.brokenRules.length;i++){
      if(this.validacijaForma.brokenRules[i].isValid==true){
        br++;
      }
    }if(br==this.validacijaForma.brokenRules.length){
      this.validno=true;
    }else{
      this.validno=false;
    }
    if(this.validno===true && this.isValid===true){
    if(this.dogadajID == undefined || this.dogadajID == null  ){
      if(e.do_aktivan==true){
        this.aktivnoZaSlanje=1;
      }else{
        this.aktivnoZaSlanje=0;
      }
      for(let i=0;i<this.noveSlike.length;i++){
        formData.append('datoteke', this.noveSlike[i])
      }
      formData.append('datum',moment(e.do_datum).format('YYYY-MM-DD'));
      formData.append('naslov',e.do_naslov);
      formData.append('opis',e.do_opis);
      formData.append('web',e.do_web);
      formData.append('aktivan',this.aktivnoZaSlanje);
      formData.append('idkorisnik',this.idkorisnik);
      formData.append('access_token',this.token);
      if(this.samoPregled===false){
      this.api.dodajDogadaj(formData).subscribe(res => {
       //console.log(res); 
       if(res.success === true){
        notify(res.message, 'success');
        this.router.navigate(['dogadjanja/']); 
      } else {
        notify(res.message, 'error');
      }
      })
      }else{
        notify("Nemate administratorska prava za dodavanje novih događaja!", 'error');
      }
    }else{
      if(e.do_aktivan==true){
        this.aktivnoZaSlanje=1;
      }else{
        this.aktivnoZaSlanje=0;
      }
      for(let i=0;i<this.noveSlike.length;i++){
        formData.append('datoteke', this.noveSlike[i])
      }
      for(const a of this.obslike){
        formData.append('obslike', a)
      }
      formData.append('datum',moment(e.do_datum).format('YYYY-MM-DD'));
      formData.append('naslov',e.do_naslov);
      formData.append('opis',e.do_opis);
      formData.append('web',e.do_web);
      formData.append('aktivan',this.aktivnoZaSlanje);
      formData.append('idkorisnik',this.idkorisnik);
      formData.append('access_token',this.token);
      formData.append('iddogadjaj',this.dogadajID);
      if(this.samoPregled===false){
      this.api.urediDogadaj(formData).subscribe(res => {
       //console.log(res); 
       if(res.success === true){
        notify(res.message, 'success');
        this.router.navigate(['dogadjanja/']); 
      } else {
        notify(res.message, 'error');
      }
      })
      }else{
        notify("Nemate administratorska prava za spremanje postojećih događaja!", 'error');
      }
    }
  }else{
    notify("Neka polja nisu pravilno popunjena!",'error');
  }
  }

  odustani(){
    this.dogadaj={};
    this.router.navigate(['dogadjanja/']);
  }

  processFile(imageInput: any) {
    if(this.samoPregled===false){
      for(let i=0;i<imageInput.files.length;i++){
        const file: File = imageInput.files[i];
        const reader = new FileReader();
        reader.addEventListener('load', (event: any) => {
          if(this.triSlike.length===3){
            notify("Možete odabrati samo tri fotografije!",'error');
          }else{
            this.triSlike.unshift(new ImageSnippet(event.target.result, file));
            this.noveSlike.push(file);
            this.triSlike[0]=this.triSlike[0].src;
          }
        });
        reader.readAsDataURL(file);
      }
    }else{
      notify("Nemate administratorska prava za dodavanje slika!", 'error');
    }
  }

  remove(slika,i){
    if(this.samoPregled===false){
      // console.log(slika===this.slikeFiles[i].putanja)
      for(let j=0;j<this.slikeFiles.length;j++){
        if(slika===this.slikeFiles[j].putanja){
          this.obslike.push(this.slikeFiles[j].idslikadogadjaj) //SLIKE ZA BRISANJE
          this.slikeFiles.splice(j,1);//SLIKE IZ BAZE -stare
        }
      }
      this.noveSlike.splice(i,1) //NOVE ZA BAZU
      this.triSlike.splice(i,1) //SLIKE ZA PRIKAZ
    }else{
      notify("Nemate administratorska prava za brisanje slika!", 'error');
    }
  }

  openImage(){
    this.slike=[];
    this.popupImageVisible=true;
    for(let i=0;i<this.triSlike.length;i++){
      this.slike.push(this.triSlike[i])
      this.slike.join(",");
    }
    this.slikeZaPrikaz=this.slike;
  }

}