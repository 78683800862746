export const navigationKorisnik = [
  {
    text: '1 - Početna',
    path: '/pocetna'
  },
  {
    text: '2 - Prijave',
    path: '/prijave'
  },
  {
    text: '3 - Obavijesti',
    path: '/obavijesti'
  },
  {
    text: '4 - Događanja',
    path: '/dogadjanja'
  },
];

export const navigationAdministrator = [
  {
    text: '1 - Početna',
    path: '/pocetna'
  },
  {
    text: '2 - Prijave',
    path: '/prijave'
  },
  {
    text: '3 - Obavijesti',
    path: '/obavijesti'
  },
  {
    text: '4 - Događanja',
    path: '/dogadjanja'
  },
  {
    text: '5 - Kontakti',
    path: '/kontakti'
  },
  {
    text: '6 - Odjeli',
    path: '/odjeli'
  },
  {
    text: '7 - Vrste problema',
    path: '/vrste-problema'
  },
  {
    text: '8 - Korisnici',
    path: '/korisnici'
  },
  // {
  //   text: '9 - Odobreni uređaji',
  //   path: '/uredjaji'
  // },
  {
    text: '9 - Grobna mjesta',
    path: '/grobna-mjesta'
  }
];