<div class="grid" >       
    <div class="kocka" [routerLink]="['/prijave']">     
        <div class="broj"> {{ brprijave }} </div>                                                  
            <div class="stat-panel-number">Zaprimljene prijave u tekućem mjesecu</div>
    </div>
    <div class="kocka" [routerLink]="['/dogadjanja']" >   
        <div class="broj"> {{ brdogadaj }} </div>                                                    
        <div class="stat-panel-number">Aktivni događaji u tekućem mjesecu</div>
    </div>
    <div class="kocka" [routerLink]="['/obavijesti']">     
        <div class="broj"> {{ brobavijesti }} </div>                                                  
            <div class="stat-panel-number">Aktivne obavijesti u tekućem mjesecu</div>
    </div>
</div>  