import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { HttpClientModule, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule, LoginFormModule } from './shared/components';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { UnauthenticatedContentModule } from './unauthenticated-content';
import { AppRoutingModule } from './app-routing.module';

import { locale } from 'devextreme/localization';
import { DevExtremeModule } from 'devextreme-angular';

import { PocetnaComponent } from './pages/pocetna/pocetna.component';
import { OdjavaComponent } from './pages/odjava/odjava.component';
import { PrijaveComponent } from './pages/prijave/prijave.component';
import { ObavijestiComponent } from './pages/obavijesti/obavijesti.component';
import { DogadjanjaComponent } from './pages/dogadjanja/dogadjanja.component';
import { KontaktiComponent } from './pages/kontakti/kontakti.component';
import { OdjeliComponent } from './pages/odjeli/odjeli.component';
import { VrsteProblemaComponent } from './pages/vrste-problema/vrste-problema.component';
import { KorisniciComponent } from './pages/korisnici/korisnici.component';
import { UredjajiComponent } from './pages/uredjaji/uredjaji.component';
import { GrobnaMjestaComponent } from './pages/grobna-mjesta/grobna-mjesta.component';
import { ObavijestComponent } from './pages/obavijesti/obavijest/obavijest.component';
import { PrijavaComponent } from './pages/prijave/prijava/prijava.component';
import { ProblemComponent } from './pages/vrste-problema/problem/problem.component';
import { KontaktComponent } from './pages/kontakti/kontakt/kontakt.component';
import { DogadajComponent } from './pages/dogadjanja/dogadaj/dogadaj.component';
import { KorisnikComponent } from './pages/korisnici/korisnik/korisnik.component';
import { GrobnoMjestoComponent } from './pages/grobna-mjesta/grobno-mjesto/grobno-mjesto.component';
import { AgmCoreModule } from '@agm/core';

locale("hr-HR");
@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authReq = req.clone({
            setHeaders: {
                'Cache-Control': 'no-cache',
                 Pragma: 'no-cache'
            }
        });
        return next.handle(authReq);    
    }
}

@NgModule({
  declarations: [
    AppComponent,
    PocetnaComponent,
    OdjavaComponent,
    PrijaveComponent,
    ObavijestiComponent,
    DogadjanjaComponent,
    KontaktiComponent,
    OdjeliComponent,
    VrsteProblemaComponent,
    KorisniciComponent,
    UredjajiComponent,
    GrobnaMjestaComponent,
    ObavijestComponent,
    PrijavaComponent,
    ProblemComponent,
    KontaktComponent,
    DogadajComponent,
    KorisnikComponent,
    GrobnoMjestoComponent,
  ],
  imports: [
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    LoginFormModule,
    UnauthenticatedContentModule,
    AppRoutingModule,
    HttpClientModule,
    DevExtremeModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCOn4AvpAUhjy89P7sL-YicxaR_IoB_R6Y',
      libraries: ["places"]
    })

  ],
  providers: [
    AuthService, 
    ScreenService, 
    AppInfoService,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: NoCacheHeadersInterceptor,
    multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }