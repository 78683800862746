<h2 style="text-align: left ;margin-left: 40px;">Prijava</h2> 
<div class="content-block dx-card">
    <div class="responsive-paddings" style="margin-left:75px;">
        <dx-load-panel
            #loadPanel
            shadingColor="rgba(0,0,0,0.4)"
            [(visible)]="loadingVisible"
            [showIndicator]="true"
            [showPane]="true"
            [shading]="true"
            [closeOnOutsideClick]="false">
        </dx-load-panel>
        <dx-form
            labelLocation="top"
            [(formData)]="prijava"
            [showColonAfterLabel]="true"
            validationGroup="forma"
            (onContentReady)="onContentReady($event)">
            <dxi-item dataField="pr_naziv" [label]="{text: 'Naslov'}" editorType="dxTextBox" [colSpan]="1"
                [editorOptions]="{ stylingMode:'outlined', width:'400px' }">
                <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2">
                <dxi-item dataField="pr_opis" [label]="{text: 'Opis prijave'}" editorType="dxTextArea" [colSpan]="1"
                    [editorOptions]="{ stylingMode:'outlined',height:'350px',  width:'85%' }">
                    <dxi-validation-rule  type="required" message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                </dxi-item>
                <dxi-item  [label]="{text: 'Adresa'}">
                    <input ngx-google-places-autocomplete [options]='options' value="{{ prijava.pr_adresa }}" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" placeholder="Unesite adresu"
                    style="width: '85%';height: 40px; background-color: transparent;border-radius: 4px;font-size: 16px; border: 0.1px solid rgb(0 0 0 / 42%);"/>
                        <dx-map
                            [center]="{ lat: 45.116733, lng: 18.537016 }"
                            [controls]="true"
                            [zoom]="15"
                            provider="google"
                            [height]="350"
                            [width]="'85%'"
                            [apiKey]="keys"> 
                        </dx-map>
                </dxi-item>
            </dxi-item>
            <dxi-item  dataField="slike" [label]="{text: ' '}" [colSpan]="1">
                <label  class="image-upload-container btn btn-bwm">
                    <span ><i class="dx-icon-image dx-icon-custom-style"> </i> </span>
                    <input [disabled]="prijavaID" #imageInput
                        style="display: none;"
                        type="file"
                        accept="image/*"
                        (change)="processFile(imageInput)">
                </label>
                        <div *ngIf="slika[0]" class="img-preview-container">
                            <div class="img-preview"
                                *ngFor="let slika of slika; let i = index"
                                 [ngStyle]="{'background-image': 'url('+ slika + ')'}"
                                 (click)="openImage()">
                                 <i *ngIf="!prijavaID" class="dx-icon-remove" style="float:right;cursor: pointer; font-size: 24px;" (click)="remove(i)"></i>
                            </div>
                        </div>
            </dxi-item>
                <dxi-item dataField="vrsta_idvrsta" [label]="{text: 'Vrsta'}" editorType="dxSelectBox" 
                    [editorOptions]="{ stylingMode:'outlined', searchEnabled:'true', dataSource: vrste, valueExpr:'idproblem', displayExpr:'pr_naziv', width:200}">
                    <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="pr_status" [label]="{text: 'Status'}" editorType="dxSelectBox" 
                    [editorOptions]="{ stylingMode:'outlined', searchEnabled:'true', dataSource: status, valueExpr:'id', displayExpr:'naziv', width:200}">
                    <dxi-validation-rule  type="required"  message="Ovo polje je potrebno ispuniti!"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="kor_blokiranje" [label]="{text: 'Blokiraj/odblokiraj korisnika'}"  editorType="dxCheckBox" cssClass="ikona"></dxi-item>
                <dxi-item>
                    <dx-button 
                        validationGroup="forma"
                        type="success"
                        (onClick)="spremi(prijava)"
                        >SPREMI
                    </dx-button>
                    <dx-button 
                        validationGroup="forma"
                        type="danger"
                        style="margin-left: 10px ;"
                        (onClick)="odustani()"
                        >ODUSTANI
                    </dx-button>
                </dxi-item>
        </dx-form>
    </div>
</div>
<!-- Popup za galeriju -->
<dx-popup
[closeOnOutsideClick]="false"
[showCloseButton]="true"
[(visible)]="popupImageVisible"
[height]="900"
[width]="1000"
title="Odabrane fotografije"
>
    <dx-gallery
      #gallery
      id="gallery"
      [dataSource]="slikaZaPrikaz"
      [loop]="true"
      [showIndicator]="true"
      [height]="800"
      [swipeEnabled]="true"
    ></dx-gallery>
</dx-popup>