import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';

import { DevExtremeModule } from 'devextreme-angular';

import { ProfileComponent } from './pages/profile/profile.component';
import { PocetnaComponent } from './pages/pocetna/pocetna.component';
import { OdjavaComponent } from './pages/odjava/odjava.component';
import { PrijaveComponent } from './pages/prijave/prijave.component';
import { ObavijestiComponent } from './pages/obavijesti/obavijesti.component';
import { DogadjanjaComponent } from './pages/dogadjanja/dogadjanja.component';
import { KontaktiComponent } from './pages/kontakti/kontakti.component';
import { OdjeliComponent } from './pages/odjeli/odjeli.component';
import { VrsteProblemaComponent } from './pages/vrste-problema/vrste-problema.component';
import { KorisniciComponent } from './pages/korisnici/korisnici.component';
import { UredjajiComponent } from './pages/uredjaji/uredjaji.component';
import { GrobnaMjestaComponent } from './pages/grobna-mjesta/grobna-mjesta.component';
import { ObavijestComponent } from './pages/obavijesti/obavijest/obavijest.component';
import { DogadajComponent } from './pages/dogadjanja/dogadaj/dogadaj.component';
import { PrijavaComponent } from './pages/prijave/prijava/prijava.component';
import { ProblemComponent } from './pages/vrste-problema/problem/problem.component';
import { KontaktComponent } from './pages/kontakti/kontakt/kontakt.component';
import { KorisnikComponent } from './pages/korisnici/korisnik/korisnik.component';
import { GrobnoMjestoComponent } from './pages/grobna-mjesta/grobno-mjesto/grobno-mjesto.component';
import { ChangePasswordFormComponent } from './shared/components/change-password-form/change-password-form.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'login',
    component: LoginFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'change-password/:recoveryCode',
    component: ChangePasswordFormComponent,
    // canActivate: [ AuthGuardService ]
  },

  {
    path: 'odjava',
    component: OdjavaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'prijave',
    component: PrijaveComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad ide na uređivanje ili prikaz
    path: 'prijave/uredi-prijavu/:id',
    component: PrijavaComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad se dodaje nova
    path: 'prijave/dodaj-prijavu',
    component: PrijavaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'obavijesti',
    component: ObavijestiComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad ide na uređivanje ili prikaz
    path: 'obavijesti/uredi-obavijest/:id',
    component: ObavijestComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad se dodaje nova
    path: 'obavijesti/dodaj-obavijest',
    component: ObavijestComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'dogadjanja',
    component: DogadjanjaComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad ide na uređivanje ili prikaz
    path: 'dogadjanja/uredi-dogadaj/:id',
    component: DogadajComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad se dodaje nova
    path: 'dogadjanja/dodaj-dogadaj',
    component: DogadajComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'kontakti',
    component: KontaktiComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad ide na uređivanje ili prikaz
    path: 'kontakti/uredi-kontakt/:id',
    component: KontaktComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad se dodaje nova
    path: 'kontakti/dodaj-kontakt',
    component: KontaktComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'odjeli',
    component: OdjeliComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'vrste-problema',
    component: VrsteProblemaComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad ide na uređivanje ili prikaz
    path: 'vrste-problema/uredi-problem/:id',
    component: ProblemComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad se dodaje nova
    path: 'vrste-problema/dodaj-problem',
    component: ProblemComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'korisnici',
    component: KorisniciComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad ide na uređivanje ili prikaz
    path: 'korisnici/uredi-korisnika/:id',
    component: KorisnikComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad se dodaje nova
    path: 'korisnici/dodaj-korisnika',
    component: KorisnikComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'uredjaji',
    component: UredjajiComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'grobna-mjesta',
    component: GrobnaMjestaComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad ide na uređivanje ili prikaz
    path: 'grobna-mjesta/uredi-grob/:id',
    component: GrobnoMjestoComponent,
    canActivate: [ AuthGuardService ]
  },
  {  /// kad se dodaje nova
    path: 'grobna-mjesta/dodaj-grob',
    component: GrobnoMjestoComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'pocetna',
    component: PocetnaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: '**',
    redirectTo: 'login',
    canActivate: [ AuthGuardService ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), DevExtremeModule],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [ProfileComponent]
})
export class AppRoutingModule { }
